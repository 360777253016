import React from 'react'
import Lips from '../images/lips-blinking.mp4'
import BikiniRed from '../images/bikini-red.mp4'
import ModelImg from '../images/models/nikky/Nikky-Dandelion-Selfie.jpg'

const Info = () => {
  return (
    <div className="w-full h-full bg-white flex flex-col justify-center items-center py-10">
      <h2 className="text-3xl font-bold select-none">ICON <span className="italic">of the week</span></h2>
      <p className="font-thin text-xl text-[#FF6F7B] select-none">This week's finest of the finest.</p>
      <div className="flex gap-x-4 flex-col md:flex-row">
        <div className="flex mt-10 mx-auto rounded-md border-8 border-black w-[200px] h-[200px]">
            <video src={Lips} width="400" height="200" autoPlay="true" loop="true" muted/>
        </div>
        <div className="flex justify-center mt-10 rounded-md border-8 border-black w-[300px] h-[300px] md:w-[400px] md:h-[400px]">
            <div className="w-full h-full flex mx-auto">
                <div id="modelweek" className="w-full h-full object-cover"></div>
            </div>
        </div>
        <div className="hidden md:flex mt-10 rounded-md border-8 border-black bg-[#E41F00] w-[200px] h-[300px]">
            <video src={BikiniRed} width="200" height="500" autoPlay="true" loop="true" muted/>
        </div>
      </div>
      <a href="https://onlyfans.com/nikkydandelion/c50" className="mt-5 font-black text-md rounded-full px-3 py-2 border-2 border-[#FF6F7B] select-none">Open a Chat</a>
    </div>
  )
}

export default Info
