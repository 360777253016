import React, { useState } from 'react';
import axios from 'axios';
import { formatDistanceToNow } from 'date-fns';
import PostDetailView from './PostDetailView';

// Your Post component
const Post = ({ post, userId, creator }) => {
  const [isLiked, setIsLiked] = useState(post.likes.includes(userId));

  ///
  const [selectedPost, setSelectedPost] = useState(null);
  const [selectedPostTip, setSelectedPostTip] = useState(null);
  const [selectedLockedPost, setSelectedLockedPost] = useState(null);

  const [user, setUser] = useState()

  const [message, setMessage] = useState()
  const [tip, setTip] = useState()
  const [justJoined, setJustJoined] = useState(true);
  ///

  const openDetailView = (post) => {
    setSelectedPost(post);
  };

  const closeDetailView = () => {
    setSelectedPost(null);
  };

  const openTipView = (post) => {
    setSelectedPostTip(post);
  };

  const closeTipView = () => {
    setSelectedPostTip(null);
  };

  const openLockedView = (post) => {
    setSelectedLockedPost(post);
  };

  const closeLockedView = () => {
    setSelectedLockedPost(null);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value)
  }

  const handleTipChange = (event) => {
    setTip(event.target.value)
  }


  const subscribeToCreator = async (creatorId, userId) => {
    try {
      // Simulate a backend API call to subscribe
      const response = await axios.post('http://localhost:4000/api/subscribe', {
        creatorId,
        userId,
      });
  
      if (response.data.message === 'Subscription successful') {
        console.log('Subscription successful');
        // Update user interface or perform other actions
      } else {
        console.log('Subscription failed');
        // Handle subscription failure
      }
    } catch (error) {
      console.error(error);
      // Handle API call error
    }
  };


  const sendTip = async (e, creatorId) => {
      
  e.preventDefault()

  console.log(creatorId)

  try {
    const response = await axios.post(`http://localhost:4000/api/tip`, {
      userId:userId,
      tip:tip,
      message:message,
      creatorId:creatorId
    });

    console.log(response.data); // Newly created user details


    // Redirect to a dashboard or home page
    window.location.href = '/'; // Assuming you have a dashboard page with URL '/dashboard'
    
  } catch (error) {
    console.error(error); // Error message from the server
  }
};

  const likePost = async () => {
    try {
    //   if (isLiked) {
    //     // If the user has already liked the post, unlike it
    //     await axios.delete(`/api/posts/unlike/${post._id}`, { data: { userId } });
    //   } else {
    //     // If the user hasn't liked the post, like it
    //     await axios.post(`/api/posts/like/${post._id}`, { userId });
    //   }

    await axios.post(`http://localhost:4000/like/${post._id}`, { userId });

      // Toggle the like status in the UI
      console.log("like this bi")
      setIsLiked(!isLiked);
    } catch (error) {
      console.error('Error toggling like:', error);
      // Handle any errors that occur during the toggling process
    }
  };

  const TimeAgo = ({ date }) => {
    const timeAgo = formatDistanceToNow(date, { addSuffix: true });
    return <span>{timeAgo}</span>;
  };

  return (
    <div className="w-full h-full flex flex-col gap-y-3 border-y py-5 hover:border-[#3BBDF8] duration-300 transition-all">
      <div className="flex gap-x-3 w-full h-fit items-center px-5">
                <img src={creator.profilePicture} alt={`Profile Picture of ChatNBang creator ${creator.username}`} className="w-[50px] h-[50px] rounded-full"/>
                <div className="flex flex-col my-2">
                  <h2 className="text-black text-md font-semibold">{creator.displayName}</h2>
                  <h3 className="text-slate-600 text-sm">@{creator.username}</h3>
                </div>
                <div className="text-gray-500 text-sm flex ml-auto">
                  <TimeAgo date={new Date(post.createdAt)} />
                </div>
              </div>
      <p className="mx-5">{post.content}</p>
      <div className="flex flex-col">
                  {user && (user.purchasedPosts?.includes(post._id) || user.subscriptions.some((subscription)=>subscription.creator === creator._id)) || (userId == creator.user) ? (

                      <>
                    {post.media.length>0 && (
                    <a className="image-container" onClick={() => openDetailView(post)}>
                      <img src={post.media} alt={`Profile Picture of ChatNBang creator ${creator.username}`} className="post-image rounded-md"/>
                    </a>
                    )}

                    <div className="flex bg-white w-full h-[40px] gap-x-4 px-7 py-3 items-center">
                      {/* {post.likes.includes(userId) &&( */}
                      <a onClick={likePost} className="flex gap-x-1 text-gray-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={0} stroke="currentColor" className={isLiked ? `w-6 h-6 fill-rose-600 hover:fill-rose-500/30 transition-all`:`w-6 h-6 fill-gray-300 hover:fill-rose-500/80 transition-all`}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
                        </svg>
                        <p className="font-semibold">{post.likes.length}</p>
                      </a>
                      {/* )}
                      {!post.likes.includes(userId) &&(
                      <a onClick={likePost} className="flex gap-x-1 text-gray-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={0} stroke="currentColor" className="w-6 h-6 fill-gray-300">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
                        </svg>
                        <p className="font-semibold">{post.likes.length}</p>
                      </a>
                      )} */}


                      <a className="flex gap-x-1 text-gray-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={0} stroke="currentColor" className="w-6 h-6 fill-gray-300 hover:fill-[#3CBCF8]/30 transition-all">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                        </svg>
                        <p className="font-semibold">{post.comments.length}</p>
                      </a>

                      <a className="flex gap-x-1 text-gray-500" onClick={() => openTipView(post)}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 stroke-1 stroke-green-50/30 fill-gray-300 hover:fill-green-500/30 transition-all">
                        <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z" />
                        <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z" clipRule="evenodd" />
                        </svg>

                        <p className="font-semibold">TIP</p>
                      </a>

                    </div>
                    </>

                  ) : (
                    <>
                     
                    {post.media.length>0 && (
                    <a onClick={() => openLockedView(post)} className="flex flex-col gap-y-2 justify-center items-center bg-slate-100 hover:bg-slate-200 hover:stroke-blue-400 hover:stroke-3 stroke-black w-full h-[300px]">
                      <div className="flex bg-slate-200 rounded-full p-4 w-fit h-fit">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={0.5} stroke="inherit" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                        </svg>
                      </div>
                      <p>Subscribe to Unlock User's Posts</p>
                    </a>
                    )}
                  <div className="flex bg-white w-full h-[40px] gap-x-4 px-7 py-3 items-center">
                    <div className="flex gap-x-1 text-gray-300">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={0.5} stroke="currentColor" className="w-6 h-6 stroke-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
                        </svg>
                      <p className="font-semibold">{post.likes.length}</p>
                    </div>

                    <div className="flex gap-x-1 text-gray-300">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={0.5} stroke="currentColor" className="w-6 h-6 stroke-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                      </svg>
                      <p className="font-semibold">{post.comments.length}</p>
                    </div>

                    <div className="flex gap-x-1 text-gray-300">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={0.5} stroke="currentColor" className="w-6 h-6 stroke-2">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                      <p className="font-semibold">Send a tip</p>
                    </div>

                  </div>
                  </>
                  )}
                </div>

      {/* <button onClick={likePost}>
        {isLiked ? 'Unlike' : 'Like'}
      </button> */}


{selectedPost && (
        <div className="fixed inset-0 flex items-center justify-center z-[200]">

          <div className="fixed rounded-lg shadow-lg p-8 z-[200]">
            <PostDetailView post={selectedPost} />
          </div>
            <div
            className="fixed inset-0 bg-black/75 flex justify-center"
            onClick={closeDetailView}
          ></div>
        </div>
      )}

      {selectedPostTip && (
    <form onSubmit={(e) => sendTip(e, creator._id)}>
        <div className="fixed inset-0 flex items-center justify-center z-[200]">

          <div className="bg-white rounded-lg shadow-lg px-4 pt-4 pb-10 w-[600px] h-fit z-[200] flex flex-col gap-y-3">
            <p className="flex gap-x-2 items-center content-center shadow pl-2 text-slate-600">How much would you like to Tip to <span className="flex ml-auto w-fit shadow py-3 pl-3 pr-14 gap-x-2 items-center content-center"><span className="flex"><img className="flex w-11 h-11 rounded-full" src={creator.profilePicture}/></span><span className="text-black text-md font-semibold text-lg items-center flex gap-x-1 capitalize">@{creator.username}
            <svg xmlns="http://www.w3.org/2000/svg" fill="#3CBCF8" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="w-7 h-7">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
            </svg>
            </span>
            </span></p>

            <div className="form-control w-full max-w-xs">
              <label className="label">
                <span className="label-text">Amount</span>
              </label>
              <input onChange={handleTipChange} type="number" placeholder="$" className="input input-bordered w-1/3 max-w-xs" />
            </div>

            <div className="flex gap-x-5">
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">Message</span>
                </label>
                <textarea
                  id="message"
                  name="message"
                  autoComplete="off" // Turn off autocomplete for textarea
                  onChange={handleMessageChange}
                  required
                  className="resize-none appearance-none border flex w-full px-3 pb-2 pt-5 rounded-md border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-none focus:z-10 sm:text-sm"
                  placeholder="Write a message"
                  rows={4} // Set the number of rows to start with
                />
              </div>
              <button className="flex px-3 py-1 w-fit h-fit rounded-md text-white mt-auto bg-[#3CBCF8] hover:bg-[#48c5ffec] duration-200 transition-all text-sm" type="submit">TIP</button>
            </div>

            <details className="collapse h-fit w-full">
              <summary className="collapse-title text-black text-md font-medium">Purchase more Balance</summary>
              <div className="collapse-content h-fit overflow-scroll min-h-[200px] w-full flex flex-col gap-y-3"> 
                <a className="w-full border-b py-2 rounded-md flex items-center px-5 hover:bg-slate-50 transition-all duration-300">
                  <p className="text-sm">$10 Wallet Balance</p>
                  <a className="px-4 py-1 bg-slate-200 text-black text-sm flex ml-auto rounded-md">$10</a>
                </a>

                <a className="w-full border-b py-2 rounded-md flex items-center px-5 hover:bg-slate-50 transition-all duration-300">
                  <p className="text-sm">$25 Wallet Balance</p>
                  <a className="px-4 py-1 bg-slate-200 text-black text-sm flex ml-auto rounded-md">$25</a>
                </a>

                <a className="w-full border-b py-2 rounded-md flex items-center px-5 hover:bg-slate-50 transition-all duration-300">
                  <p className="text-sm">$50 Wallet Balance</p>
                  <a className="px-4 py-1 bg-slate-200 text-black text-sm flex ml-auto rounded-md">$50</a>
                </a>
                
                <a className="w-full border-b py-2 rounded-md flex items-center px-5 hover:bg-slate-50 transition-all duration-300">
                  <p className="text-sm">$100 Wallet Balance</p>
                  <a className="px-4 py-1 bg-slate-200 text-black text-sm flex ml-auto rounded-md">$100</a>
                </a>

                <a className="w-full border-b py-2 rounded-md flex items-center px-5 hover:bg-slate-50 transition-all duration-300">
                  <p className="text-sm">$500 Wallet Balance</p>
                  <a className="px-4 py-1 bg-slate-200 text-black text-sm flex ml-auto rounded-md">$500</a>
                </a>
                
                
              </div>
            </details>

          </div>
            <div
            className="fixed inset-0 bg-black/40 flex justify-center"
            onClick={closeTipView}
          ></div>
        </div>

      </form>
      
      )}

       {selectedLockedPost && (
        <div className="fixed inset-0 flex items-center justify-center">

          <div className="flex flex-col bg-white rounded-lg shadow-lg w-[400px] h-[500px] z-[200]">
            <div className="relative w-full h-[140px] overflow-hidden">
            <img className="flex absolute object-cover w-fit h-fit" src={creator.bannerImage}/>
            </div>
          
            <div className="flex -mt-14 ml-4 w-fit h-fit rounded-full border-2 border-white z-[10]">
              <img className="w-[90px] h-[90px] rounded-full" src={creator.profilePicture}/>
            </div>

            <div className="flex flex-col w-full mt-2 px-6 justify-start">
              <h2 className="font-semibold text-black text-2xl tracking-wide capitalize">{creator.displayName}</h2>
              <h2 className="font-semibold text-slate-500 text-md tracking-wide capitalize">@{creator.username}</h2>
            </div>

            <div className="flex flex-col gap-y-2 px-6 mt-6">
              <p className="font-semibold">Subscribe & Receive:</p>
              <p className="flex gap-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-[#3BBDF8]">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
              Full access to this user's content
              </p>
              <p className="flex gap-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-[#3BBDF8]">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
              Direct message with this user
              </p>
              <p className="flex gap-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-[#3BBDF8]">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
              Cancel your subscription at any moment
              </p>
            </div>

            <div className="w-full h-fit flex px-10 py-5 mt-auto">
              <button onClick={(e)=>subscribeToCreator(creator._id, userId)} className="w-full h-fit py-2 px-4 bg-[#3BBDF8] hover:bg-[#58caff] transition-all duration-200 rounded-full text-white font-bold text-center">Subscribe</button>
            </div>
          </div>
            <div
            className="fixed inset-0 bg-black/20 flex justify-center z-[0]"
            onClick={closeLockedView}
          ></div>
        </div>
      )}
    </div>

    
  );
};

export default Post;
