import React from 'react'
import OneImg from '../images/models/nikky/2024-04-10 17.33.20.jpg'
import Model1 from '../images/models/search/model1.png'
import Model2 from '../images/models/search/model2.png'
import Model3 from '../images/models/search/model3.png'
import Model4 from '../images/models/search/model4.png'
import Model5 from '../images/models/search/model5.png'
import Model6 from '../images/models/search/model6.png'
import Model7 from '../images/models/search/model7.png'
import Model8 from '../images/models/search/model8.png'
import Model9 from '../images/models/search/model9.png'
import Model10 from '../images/models/search/model10.png'
import Model11 from '../images/models/search/model11.png'




const Models = () => {
  return (
    <div className="flex w-screen h-full bg-black">
      <div className="h-screen w-1/5 hidden md:flex">
          <ul className="flex flex-col mt-20 select-none">
              <li className="flex">
                  <a href="/" className="flex gap-x-4 w-full py-2 px-4 items-center">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                        </svg>
                    </div>
                    <p className="text-white font-bold text-sm text-center">Home</p>
                </a>
              </li>
              <li className="flex">
                  <a className="flex gap-x-4 w-full py-2 px-4 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                </svg>
                  <p className="text-white font-bold text-sm text-center">Members</p>
                  </a>
              </li>
          </ul>
      </div>
      <div className="flex flex-col w-full h-screen pt-10 md:pr-20">
          <div className="flex w-full justify-center md:justify-start">
            <h2 className="flex justify-center text-white text-3xl font-black select-none md:text-left text-center">CHAT'N'BANG</h2>
          </div>
          <div className="w-full items-center mt-10 justify-center grid grid-cols-1 md:grid-cols-5 gap-x-1 gap-y-1 transition-all duration-300">
            <a href="/model/nikky" className="relative w-full h-[300px] aspect-w-16 aspect-h-3 select-none">
                <div className="absolute right-1 top-2 rounded-full bg-green-500 shadow shadow-green-500 w-3 h-3"></div>
                <img src={OneImg} className="object-cover w-full h-full hover:pointer-events-none" alt="Image"/>
                <div class="absolute flex inset-0 bg-gradient-to-b from-transparent via-transparent to-black/70 hover:to-black/0 w-full h-[300px] "></div>
                <div className="absolute bottom-3 flex w-fit ml-5">
                    <h3 className="text-white text-sm font-bold z-[20]">Nikky Dandelion</h3>
                </div>
            </a>
            <div className="relative w-full h-[300px] aspect-w-16 aspect-h-3 select-none">
                <div className="absolute right-1 top-2 rounded-full bg-red-500 shadow shadow-red-500 w-3 h-3"></div>
                <img src={Model2} className="object-cover w-full h-full hover:pointer-events-none" alt="Image"/>
                <div class="absolute flex inset-0 bg-gradient-to-b from-transparent via-transparent to-black/70 hover:to-black/0 w-full h-[300px] "></div>
                <div className="absolute bottom-3 flex w-fit ml-5">
                    <h3 className="text-white text-sm font-bold z-[20]">Claire Schroeder</h3>
                </div>
            </div>
            <div className="relative w-full h-[300px] aspect-w-16 aspect-h-3 select-none">
                <div className="absolute right-1 top-2 rounded-full bg-red-500 shadow shadow-red-500 w-3 h-3"></div>
                <img src={Model3} className="object-cover w-full h-full hover:pointer-events-none" alt="Image"/>
                <div class="absolute flex inset-0 bg-gradient-to-b from-transparent via-transparent to-black/70 hover:to-black/0 w-full h-[300px] "></div>
                <div className="absolute bottom-3 flex w-fit ml-5">
                    <h3 className="text-white text-sm font-bold z-[20]">Molly Eskam</h3>
                </div>
            </div>
            <div className="relative w-full h-[300px] aspect-w-16 aspect-h-3 select-none">
                <div className="absolute right-1 top-2 rounded-full bg-red-500 shadow shadow-red-500 w-3 h-3"></div>
                <img src={Model4} className="object-cover w-full h-full hover:pointer-events-none" alt="Image"/>
                <div class="absolute flex inset-0 bg-gradient-to-b from-transparent via-transparent to-black/70 hover:to-black/0 w-full h-[300px] "></div>
                <div className="absolute bottom-3 flex w-fit ml-5">
                    <h3 className="text-white text-sm font-bold z-[20]">devenity</h3>
                </div>
            </div>
            <div className="relative w-full h-[300px] aspect-w-16 aspect-h-3 select-none">
                <div className="absolute right-1 top-2 rounded-full bg-red-500 shadow shadow-red-500 w-3 h-3"></div>
                <img src={Model5} className="object-cover w-full h-full hover:pointer-events-none" alt="Image"/>
                <div class="absolute flex inset-0 bg-gradient-to-b from-transparent via-transparent to-black/70 hover:to-black/0 w-full h-[300px] "></div>
                <div className="absolute bottom-3 flex w-fit ml-5">
                    <h3 className="text-white text-sm font-bold z-[20]">Genny Shawcross</h3>
                </div>
            </div>
            <div className="relative w-full h-[300px] aspect-w-16 aspect-h-3 select-none">
                <div className="absolute right-1 top-2 rounded-full bg-red-500 shadow shadow-red-500 w-3 h-3"></div>
                <img src={Model6} className="object-cover w-full h-full hover:pointer-events-none" alt="Image"/>
                <div class="absolute flex inset-0 bg-gradient-to-b from-transparent via-transparent to-black/70 hover:to-black/0 w-full h-[300px] "></div>
                <div className="absolute bottom-3 flex w-fit ml-5">
                    <h3 className="text-white text-sm font-bold z-[20]">Samantha</h3>
                </div>
            </div>
            <div className="relative w-full h-[300px] aspect-w-16 aspect-h-3 select-none">
                <div className="absolute right-1 top-2 rounded-full bg-red-500 shadow shadow-red-500 w-3 h-3"></div>
                <img src={Model7} className="object-cover w-full h-full hover:pointer-events-none" alt="Image"/>
                <div class="absolute flex inset-0 bg-gradient-to-b from-transparent via-transparent to-black/70 hover:to-black/0 w-full h-[300px] "></div>
                <div className="absolute bottom-3 flex w-fit ml-5">
                    <h3 className="text-white text-sm font-bold z-[20]">Mikayla Demaiter</h3>
                </div>
            </div>
            <div className="relative w-full h-[300px] aspect-w-16 aspect-h-3 select-none">
                <div className="absolute right-1 top-2 rounded-full bg-red-500 shadow shadow-red-500 w-3 h-3"></div>
                <img src={Model8} className="object-cover w-full h-full hover:pointer-events-none" alt="Image"/>
                <div class="absolute flex inset-0 bg-gradient-to-b from-transparent via-transparent to-black/70 hover:to-black/0 w-full h-[300px] "></div>
                <div className="absolute bottom-3 flex w-fit ml-5">
                    <h3 className="text-white text-sm font-bold z-[20]">Isabelle</h3>
                </div>
            </div>
            <div className="relative w-full h-[300px] aspect-w-16 aspect-h-3 select-none">
                <div className="absolute right-1 top-2 rounded-full bg-red-500 shadow shadow-red-500 w-3 h-3"></div>
                <img src={Model9} className="object-cover w-full h-full hover:pointer-events-none" alt="Image"/>
                <div class="absolute flex inset-0 bg-gradient-to-b from-transparent via-transparent to-black/70 hover:to-black/0 w-full h-[300px] "></div>
                <div className="absolute bottom-3 flex w-fit ml-5">
                    <h3 className="text-white text-sm font-bold z-[20]">Madisyn Shipman</h3>
                </div>
            </div>
            <div className="relative w-full h-[300px] aspect-w-16 aspect-h-3 select-none">
                <div className="absolute right-1 top-2 rounded-full bg-red-500 shadow shadow-red-500 w-3 h-3"></div>
                <img src={Model11} className="object-cover w-full h-full hover:pointer-events-none" alt="Image"/>
                <div class="absolute flex inset-0 bg-gradient-to-b from-transparent via-transparent to-black/70 hover:to-black/0 w-full h-[300px] "></div>
                <div className="absolute bottom-3 flex w-fit ml-5">
                    <h3 className="text-white text-sm font-bold z-[20]">Leo</h3>
                </div>
            </div>
          </div>
      </div>
    </div>
  )
}

export default Models
