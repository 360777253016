import axios from 'axios';
import React, {useEffect, useState} from 'react'
import Navbar from './Navbar';
import Navigate from './Navigate';
import Drawer from './Drawer';
import Sidebar from './Sidebar';
import PostDetailView from './PostDetailView';
import fetchUserData from '../api/userData'
import fetchUserToken from '../api/userToken'
import { formatDistanceToNow } from 'date-fns';
import Post from './Post'
import SidebarMini from './SidebarMini';
import Ad from './Ad';
const Home = () => {

    const [creators, setCreators] = useState([]);

    const [username, setUsername] = useState([])

    const [pfp, setPfp] = useState()

    const [posts, setPosts] = useState([])

    const [timeAgo, setTimeAgo] = useState([])

    const [selectedPost, setSelectedPost] = useState(null);
    const [selectedPostTip, setSelectedPostTip] = useState(null);
    const [selectedLockedPost, setSelectedLockedPost] = useState(null);

    const [user, setUser] = useState()
    const [userId, setUserId] = useState()

    const [message, setMessage] = useState()
    const [tip, setTip] = useState()

    const [isLiked, setIsLiked] = useState(false);
    const [justJoined, setJustJoined] = useState(true);
    const [token, setToken] = useState()
    const [gifs, setGifs] = useState([])

    const openDetailView = (post) => {
      setSelectedPost(post);
    };

    const closeDetailView = () => {
      setSelectedPost(null);
    };

    const openTipView = (post) => {
      setSelectedPostTip(post);
    };
  
    const closeTipView = () => {
      setSelectedPostTip(null);
    };

    const openLockedView = (post) => {
      setSelectedLockedPost(post);
    };
  
    const closeLockedView = () => {
      setSelectedLockedPost(null);
    };

    const handleMessageChange = (event) => {
      setMessage(event.target.value)
    }

    const handleTipChange = (event) => {
      setTip(event.target.value)
    }
  
    
    useEffect(() => {

    if(creators.length=0){
      axios.get('http://localhost:4000/api/creators')
      .then(response => {
        const creatorsData = response.data;
        setCreators(creatorsData);

        console.log(creators)

      })
      .catch(error => {
        console.error('Error fetching creators:', error);
      });
    }

  //   if(token=''){

  //       fetch('https://api.redgifs.com/v2/auth/temporary', {
  //   method: 'GET',
  //   headers: {
  //     'accept': 'application/json'
  //   }
  // })
  //   .then(response => response.json())
  //   .then(data => {
  //     setToken(data.token)
  //   })
  //   .catch(error => {
  //     console.error('Error:', error);
  //   });


    if(token!='' && gifs.length==0){
    axios.get('https://api.redgifs.com/v2/feeds/home', {
      headers: {
        'accept': 'application/json',
        'authorization':`Bearer ${token}`
      }
    })
    .then(data => {
      console.log(data)
    })
    .catch(error => {
      console.error('Error:', error);
    });

    }

      axios.get('http://localhost:4000/api/posts')
      .then(response => {
        const postsData = response.data;
        console.log(postsData)
        setPosts(postsData);

      })
      .catch(error => {
        console.error('Error fetching creators:', error);
      });

      // if(posts){
      //   for(const post in posts) {
      //     const time = formatDistanceToNow(new Date(post.createdAt), { addSuffix: true });
      //     setTimeAgo((prevTime) => [...prevTime, time])
      //   }
      // }

      const fetchUser = async () =>{
        const userData = await fetchUserData();
        const token = await fetchUserToken()

        setUser(userData)
        setUserId(userData._id)
        setToken(token)
        console.log(userData)

      }
      fetchUser()

    }, [posts, user])

// console.log(posts[0].creator.profilePicture)

    const TimeAgo = ({ date }) => {
      const timeAgo = formatDistanceToNow(date, { addSuffix: true });
      return <span>{timeAgo}</span>;
    };

    const subscribeToCreator = async (creatorId, userId) => {
      try {
        // Simulate a backend API call to subscribe
        const response = await axios.post('http://localhost:4000/api/subscribe', {
          creatorId,
          userId,
        });
    
        if (response.data.message === 'Subscription successful') {
          console.log('Subscription successful');
          // Update user interface or perform other actions
        } else {
          console.log('Subscription failed');
          // Handle subscription failure
        }
      } catch (error) {
        console.error(error);
        // Handle API call error
      }
    };

    // const isSubscribedToCreator = user?.subscriptions?.includes(posts[5].creator._id);
//     console.log(isSubscribedToCreator)

//     console.log(typeof user.subscriptions);
// console.log(posts[5].creator);

const sendTip = async (e, creatorId) => {
      
  e.preventDefault()

  console.log(creatorId)

  try {
    const response = await axios.post(`http://localhost:4000/api/tip`, {
      userId:userId,
      tip:tip,
      message:message,
      creatorId:creatorId
    });

    console.log(response.data); // Newly created user details


    // Redirect to a dashboard or home page
    window.location.href = '/'; // Assuming you have a dashboard page with URL '/dashboard'
    
  } catch (error) {
    console.error(error); // Error message from the server
  }
};

const likePost = async (userId, postId) => {
  
  try {


    if(isLiked==true){
      setIsLiked(false)
    }

    if(isLiked==false){
      setIsLiked(true)
    }

    setJustJoined(false)

    const response = await axios.post('http://localhost:4000/api/posts/like', {
      userId: userId,
      postId: postId,
    });

    console.log(response.data); // This will print a success message from the server if the post is liked successfully.

    // You can also update your user interface to reflect that the post has been liked.
  } catch (error) {
    console.error(error); // Handle any errors that occur during the request.
  }
};



  return (
    
    <>
    <div className="h-full w-screen flex dark:bg-tabublack/90 bg-white">
      <div className="flex w-1/8 h-screen fixed">
      <SidebarMini/>
      </div>

 
    <div className="flex w-2/4 ml-auto h-full min-h-screen py-5 dark:bg-tabublack/90 bg-white">
     
        <div className="w-full h-full flex flex-col gap-y-5 justify-center">

          <div className="flex justify-start w-full gap-x-4 py-3 px-10 border-b select-none">
            <a href="/finder/onlyfans" className="flex py-1 px-4 rounded-full text-white bg-[#8ac7e4e3] hover:bg-[#77b3cfe3] gap-x-1 hover:opacity-90 duration-200 transition-all hover:cursor-pointer">
              <p className="font-semibold">Top</p>
            </a>
            <a href="/finder/onlyfans-new" className="flex py-1 px-4 rounded-full text-white bg-[#8ac7e4e3] hover:bg-[#77b3cfe3] gap-x-1 hover:opacity-90 duration-200 transition-all hover:cursor-pointer">
              <p className="font-semibold">New</p>
            </a>
            <a href="/finder/onlyfans-free" className="flex py-1 px-4 rounded-full text-white bg-[#8ac7e4e3] hover:bg-[#77b3cfe3] gap-x-1 hover:opacity-90 duration-200 transition-all hover:cursor-pointer">
              <p className="font-semibold">Free</p>
            </a>
            <a href="/promote" className="px-2 py-1 flex bg-[#3BBDF8] hover:bg-[#36afe7] transition-all duration-200 w-fit rounded-[9px] text-white select-none font-semibold ml-auto">
            
            Promote
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="flex ml-1 w-6 h-6 stroke-amber-400 fill-rose-500">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z" />
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z" />
            </svg>
            </a>
          </div>

          <div className="w-full h-full flex flex-col select-none justify-center items-center content-center">
          {posts.map((post, index) => (
            <Post key={post._id} post={post} userId={userId} creator={post.creator} buyer={user} />
          ))}
          

        
      </div>
          {/* <div className="w-full h-full flex flex-col gap-4 select-none justify-center items-center content-center">
          {creators.map((creator, index) => (
            <a key={creator._id} className="w-full min-h-[500px] px-10 h-full hover:border-[#3BBDF8] duration-300 transition-all flex flex-col gap-y-2 justify-start">
              <div className="flex gap-x-3 w-full h-fit items-center">
                <img src={creator.profilePicture} alt={`Profile Picture of ChatNBang creator ${creator.username}`} className="w-[60px] h-[60px] rounded-full"/>
                <div className="flex flex-col gap-y-1 my-2">
                  <h2 className="text-black text-lg font-bold">{creator.username}</h2>
                  <h3 className="text-black text-sm">@{creator.username}</h3>
                </div>
              </div>
              <p>{creator.bio}</p>
            </a>
          ))}
          </div> */}

 
        </div>

    </div>

    {/* <div className="w-1/4 min-h-screen h-full flex flex-col p-2 bg-white">
       <Ad/>
    </div> */}
    
    </div>
    </>
    
  )
}

export default Home
