/* global gtag */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Redirect = ({ to, trackingData }) => {

  const navigate = useNavigate();

  useEffect(() => {
    // Send tracking data to Google Analytics or any other analytics service
    if (trackingData) {
      // Replace 'YOUR_GA_TRACKING_ID' with your actual Google Analytics tracking ID
      gtag('event', 'link_click', {
        'event_category': 'Redirect Link Click',
        'event_label': trackingData
      });
    }

    const url = `https://www.onlyfans.com/${to}`

    // Redirect to the desired URL after tracking
    navigate(to);
  }, [to, trackingData]);

  return null; // This component doesn't render anything
};

export default Redirect;
