import axios from 'axios';
import React from 'react'

const fetchUserToken = async () => {
  try {

    const response = await axios.get('https://api.redgifs.com/v2/auth/temporary', {
      headers: {
        'accept': 'application/json'
      }
    })
    return response.data.token;
  } catch (error) {
    console.error('Error fetching user data', error);
    return null;
  }
};

export default fetchUserToken;
