import React, {useState} from 'react'
import useDarkSide from '../hook/useDarkSide'
import { DarkModeSwitch } from 'react-toggle-dark-mode'


const SwitcherNav = () => {

    const [colorTheme, setTheme] = useDarkSide();
    const [darkSide, setDarkSide] = useState( colorTheme==="light" ? true : false);

    const toggleDarkMode = (checked) => {
        setTheme(colorTheme)
        setDarkSide(checked)
        console.log(colorTheme, "them")
    }

  return (
    <div className="">
      <DarkModeSwitch
        checked={darkSide}
        onChange={toggleDarkMode}
        size={26}
        
    />
    </div>
  )
}

export default SwitcherNav
