import React, {useState, useEffect} from 'react';
import EmojiPicker from 'emoji-picker-react';
import NavbarLeft from './NavbarLeft';
import Drawer from './Drawer';
import socketIOClient from 'socket.io-client';

const socket = socketIOClient('http://localhost:4000');

const ChatApp = () => {

  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  useEffect(() => {
    // Listen for incoming messages
    socket.on('chat message', (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    // Clean up the socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, []);

  const sendMessage = () => {
    if (input.trim() !== '') {
      // Send the message to the server
      socket.emit('chat message', input);

      // Clear the input field
      setInput('');
    }
  };

  return (
    <div className="flex h-screen">
        {/* <Drawer/> */}
      {/* Chat List */}
      <div className="flex flex-col w-1/3 bg-white border-r">
        <div className="px-4 py-6 text-center">
          <h1 className="text-2xl font-bold text-[#3ABEF8]">Chats</h1>
        </div>
        <ul className="flex-grow overflow-auto text-black">
          <li className="px-4 py-2 hover:bg-blue-200 cursor-pointer">
            <div className="flex items-center">
              <img
                src="https://source.unsplash.com/random/50x50"
                alt="Profile"
                className="w-10 h-10 rounded-full"
              />
              <div className="flex-grow ml-4">
                <h2 className="text-lg font-medium">John</h2>
                <p className="text-sm text-gray-500">
                  Hi there! How can I help you today?
                </p>
              </div>
              <div className="text-sm text-gray-500">
                <p>9:00 AM</p>
                <div className="bg-blue-500 h-2 w-2 rounded-full"></div>
              </div>
            </div>
          </li>
          <li className="px-4 py-2 hover:bg-blue-200 cursor-pointer">
            <div className="flex items-center">
              <img
                src="https://source.unsplash.com/random/50x50"
                alt="Profile"
                className="w-10 h-10 rounded-full"
              />
              <div className="flex-grow ml-4">
                <h2 className="text-lg font-medium">Jane</h2>
                <p className="text-sm text-gray-700">
                  Hey! Have you seen the new movie?
                </p>
              </div>
              <div className="text-sm text-gray-500">
                <p>Yesterday</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      {/* Chat Window */}
      <div className="flex-grow bg-white h-full w-full">
        <div className="max-w-4xl mx-auto px-4 py-6 h-[90%]">
          <div className="flex items-center justify-between mb-4">
            <h1 className="text-2xl font-bold">Chatting with John</h1>
            {/* <button className="px-3 py-2 rounded-full bg-black text-white font-medium">
              End Chat
            </button> */}
          </div>
          <div className="space-y-4">
            <div className="flex space-x-2 items-start">
              <img
                src="https://source.unsplash.com/random/50x50"
                alt="Profile"
                className="w-10 h-10 rounded-full"
              />
              <div className="bg-white p-4 rounded-lg shadow-md">
                <p className="mb-2 font-medium">John</p>
                <p className="text-sm text-gray-700">
                  Hi there! How can I help you today?
                </p>
              </div>
            </div>
            <div className="flex space-x-2 items-start">
              <img
                src="https://source.unsplash.com/random/50x50"
                alt="Profile"
                className="w-10 h-10 rounded-full"
              />
              <div className="bg-white p-4 rounded-lg shadow-md">
                <p className="mb-2 font-medium">You</p>
                <p className="text-sm text-gray-700">
                  Hi John, I have a question about my account.
                </p>
              </div>
            </div>
            <div className="flex space-x-2 items-start">
              <img
                src="https://source.unsplash.com/random/50x50"
                alt="Profile"
                className="w-10 h-10 rounded-full"
              />
              <div className="bg-white p-4 rounded-lg shadow-md">
                <p className="mb-2 font-medium">John</p>
                <p className="text-sm text-gray-700">
                  Sure thing, what can I help you with?
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-auto w-full h-fit place-self-end">
            <div className="bg-blue-100 py-4">
            <div className="max-w-2xl mx-auto px-4">
                <form className="flex space-x-2">
                <input
                    type="text"
                    className="flex-grow border border-gray-300 rounded-lg px-4 py-2"
                    placeholder="Type your message..."
                />
                 <button className="px-2 py-2 rounded-full bg-blue-600 text-white font-medium">
                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                    </svg>
                </button>

                <button className="px-4 py-2 rounded-full bg-blue-600 text-white font-medium">
                    Send
                </button>

                {/* <div className="absolute bottom-10">
                    <EmojiPicker height={400} width={400} />
                </div> */}

                </form>
            </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default ChatApp;