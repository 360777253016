import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom';
import AgeVerification from './AgeVerification';

const LandingDark = () => {

    const [clickId, setClickId] = useState("");
    const [campaign, setCampaign] = useState("");
    const [creativeId, setCreativeId] = useState("");
    const [campaignId, setCampaignId] = useState("");
    const [referrer, setReferrer] = useState("");


    const location = useLocation();
  
    // Extract click ID and key from URL parameters
    const params = new URLSearchParams(location.search);
    // const clickId = params.get('click_id');
    // const key = params.get('key');

    // const postbackUrl = `https://tsyndicate.com/api/v1/cpa/action?clickid=${clickId}&key=${key}`;
    // const postbackUrl = `https://tsyndicate.com/api/v1/cpa/action?clickid=${clickId}&key=0ZQqHn41aEhUfIUzZbdRj531Ea18t18iQOyo`
    const constructedURL = `https://www.chatnbang.com/ella?click_id=YOUR_CLICK_ID&campaign=YOUR_CAMPAIGN&creative_id=YOUR_CREATIVE_ID&campaign_id=YOUR_CAMPAIGN_ID&referrer=YOUR_REFERRER`;
    
    const url = `https://www.chatnbang.com/nikky?click_id=${clickId}&campaign=${campaign}&creative_id=${creativeId}&campaign_id=${campaignId}&referrer=${referrer}`

    useEffect(() => {
        // Function to extract URL parameters
        const getUrlParams = () => {

            const urlParams = new URLSearchParams(window.location.search);
            const clickIdParam = urlParams.get('click_id');
            const campaignParam = urlParams.get('campaign');
            const creativeIdParam = urlParams.get('creative_id');
            const campaignIdParam = urlParams.get('campaign_id');
            const referrerParam = urlParams.get('referrer');

            setClickId(clickIdParam);
            setCampaign(campaignParam);
            setCreativeId(creativeIdParam);
            setCampaignId(campaignIdParam);
            setReferrer(referrerParam);

            //http://localhost:3000/ella?click_id=123&campaign=my_campaign&creative_id=456&campaign_id=789&referrer=example.com
            //https://www.chatnbang.com?click_id=123&campaign=my_campaign&creative_id=456&campaign_id=789&referrer=example.com

        };
    
        // Function to upload data to MongoDB
        const uploadToMongoDB = (data) => {

            const urlParams = new URLSearchParams(window.location.search);
            const clickIdParam = urlParams.get('click_id');
            const campaignParam = urlParams.get('campaign');
            const creativeIdParam = urlParams.get('creative_id');
            const campaignIdParam = urlParams.get('campaign_id');
            const referrerParam = urlParams.get('referrer');


            // const apiUrl = 'http://localhost:4000/api/upload'; 
            // const apiUrl = 'https://www.chatnbang.com/api/upload'; 
            const apiUrl = 'https://srv498730.hstgr.cloud:4001/api/upload'

            axios.post(apiUrl, {
                click_id: clickIdParam,
                campaign: campaignParam,
                creative_id: creativeIdParam,
                campaign_id: campaignIdParam,
                referrer: referrerParam
            }, {
                withCredentials: true
              })
                .then(response => {
                console.log('Data uploaded successfully:', response.data);
                })
                .catch(error => {
                console.error('Error uploading data:', error);
            });
        };
    
        // Extract URL parameters and upload to MongoDB
        getUrlParams();
        const params = { click_id: clickId, campaign:campaign, creative_id: creativeId, campaign_id: campaignId, referrer:referrer };
        uploadToMongoDB(params);
      }, [location.search]); // Empty dependency array ensures this effect runs only once after initial render

    // // Send GET request to the S2S Postback URL
    // axios.get(postbackUrl)
    // .then((response) => {
    //     // Handle the response from the postback URL if needed
    //     console.log('Postback request successful:', response.data);
    // })
    // .catch((error) => {
    //     console.error('Postback request failed:', error);
    //     // Handle request failure if needed
    // });

    // const requestData = {
    //     click_id: clickId,
    //     key: key,
    //     // Add other user information as needed
    //     // For example:
    //     // name: 'John Doe',
    //     // email: 'john@example.com',
    //     // age: 30,
    //     // etc.
    //   };

    // axios.get('http://localhost:4000/aduser', {
    //     params: requestData // Pass request parameters here
    //   })
    //     .then((response) => {
    //         // Handle the response from the protected route
    //         setData(response.data);
    //         console.log(response.data)
    //     })
    //     .catch((error) => {
    //         console.error('Request failed', error);
    //         // Handle request failure or redirect to login page
    // });
    
  return (
    <div className="bg-black">
        <div className="md:hidden animate-pulse fixed flex flex-col bottom-0 z-[201] px-6 py-4 bg-black left-0 border-b-4 select-none border-red-800 shadow-red-400 shadow-xl rounded-md opacity-[90%]">
                <h2 className="text-xl font-semibold text-white">Eligible</h2>
                <h2 className="text-lg text-white">Claim Nikky's subscription for <span className="font-bold text-red-600"><a href="https://onlyfans.com/your.dandelion/c23">FREE</a></span> 😈</h2>
            </div>
        {/* <div className="fixed justify-center md:hidden bg-black/90 w-screen h-screen items-center p-10 rounded-md mx-auto flex flex-col select-none z-[200]">
                <h2 className="font-semibold text-2xl text-center text-white">Age Verification</h2>
                <h3 className="font-normal text-md text-center text-white">Are you 18 or older?</h3>
                <div className="w-full flex gap-x-3 justify-center items-center mt-4">
                    <a href="https://onlyfans.com/your.dandelion/c23" className="shadow-lg w-[200px] h-[35px] md-h-fit md-w-fit pt-1 text-center shadow-red-600/60 rounded-full md:px-7 md:py-3 items-center justify-center content-center border-2 text-white bg-red-600 border-red-600 hover:bg-black hover:text-[#01AEF0] hover:cursor-pointer"><span className="flex md:mb-0 text-center justify-center content-center">YES</span></a>
                    <a className="rounded-full w-[200px] h-[35px] md-h-fit md-w-fit md:px-7 md:py-3 pt-1 items-center justify-center content-center border-2 opacity-[50%] border-red-600 hover:text-red-500"><span className="flex md:mb-0 text-center justify-center content-center">NO</span></a>
                </div>
        </div> */}
        <AgeVerification/>
        <div className="hidden md:flex md:absolute items-center justify-center md:w-screen md:h-screen flex bg-black/80 z-[200]">
            {/* <div className="animate-pulse absolute flex flex-col bottom-0 px-6 py-4 bg-purple-800/50 rounded-md left-0 border-b-4 select-none border-red-600 opacity-[90%]">
                <h2 className="animate-pulse text-xl font-semibold text-white">Eligible</h2>
                <h2 className="text-lg text-white">Claim Nikky's subscription for <span className="font-bold text-red-600">FREE</span> 😈</h2>
            </div> */}
            <div className="bg-black border-x border-t border-b-2 border-b-red-600 shadow shadow-b shadow-red-500 border-white/20 w-[40%] p-20 rounded-md flex flex-col select-none">
                <h2 className="animate-pulse font-semibold text-2xl text-center text-white">Age Verification</h2>
                <h3 className="font-normal text-md text-center text-white">Are you 18 or older?</h3>
                <div className="w-full flex gap-x-3 justify-center items-center mt-4">
                    <a href="https://onlyfans.com/your.dandelion/c23" className="shadow-lg shadow-red-500/80 rounded-full px-7 py-2 border-2 hover:text-white hover:bg-red-600 border-red-500 hover:border-red-700 bg-black text-red-500 hover:cursor-pointer">YES</a>
                    <a className="rounded-full px-7 py-2 border-2 opacity-[50%] border-red-400 hover:text-red-500 text-white/70">NO</a>
                </div>
            </div>
        </div>
        <div className="flex w-screen h-screen">
        <div id="roboto" className="w-1/4 h-screen px-20 hidden md:flex md:flex-col gap-y-5 font-roboto py-2">
            <div className="bg-gray-700 mt-2 rounded-full p-1 w-9 h-9 flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="w-6 h-6 opacity-[80%]">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                </svg>
            </div>
            <div className="w-full flex items-center gap-x-6 text-[#8996A3] font-[600] text-[19px] pl-[2px]">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.6} stroke="#8996A3" className="w-7 h-7">
                <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                </svg>
                <h2>Home</h2>
            </div>
            <div className="w-full flex items-center gap-x-6 text-[#8996A3] font-[600] text-[19px] pl-[2px]">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.6} stroke="#8996A3" className="w-7 h-7">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                <h2>More</h2>
            </div>
        </div>
        <div className="border-x border-gray-200/10 md:w-[42%] w-screen h-screen bg-black/90 flex flex-col">
            <div className="flex w-full h-48">
                <div class="vignette w-screen md:w-[41.7%] h-48 opacity-[50%] flex"></div>
                <div id="ellaheader" className="flex flex-col gap-x-3 items-center w-full pb-36 px-4 border-b border-gray-200 shadow-inner">
                    <div className="flex w-full gap-x-3 items-end">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="white" class="flex mt-4 w-5 h-6 z-[100]">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                        </svg>
                        <div className="flex flex-col z-[100]">
                            <h2 className="text-white text-xl font-[500] flex items-center">Petite Nikky🌼
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 flex">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
                            </svg>
                            </h2>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" strokeWidth={2.2} stroke="white" className="flex mt-4 w-7 h-7 ml-auto z-[100]">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
                        </svg>
                    </div>
                    <div className="w-full flex gap-x-6 px-8 z-[100]">
                        <div className="flex gap-x-1 text-white font-bold text-sm items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="w-4 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                            </svg>
                            <h3>41</h3>
                        </div>
                        <div className="flex gap-x-1 text-white font-bold text-sm items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
                            </svg>
                            <h3>27.1K</h3>
                        </div>
                        {/* <div className="flex gap-x-1 text-white font-bold text-sm items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z" />
                            </svg>
                            <h3>27.1K</h3>
                        </div> */}
                        <div className="flex gap-x-1 text-white font-bold text-sm items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.348 14.652a3.75 3.75 0 0 1 0-5.304m5.304 0a3.75 3.75 0 0 1 0 5.304m-7.425 2.121a6.75 6.75 0 0 1 0-9.546m9.546 0a6.75 6.75 0 0 1 0 9.546M5.106 18.894c-3.808-3.807-3.808-9.98 0-13.788m13.788 0c3.808 3.807 3.808 9.98 0 13.788M12 12h.008v.008H12V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                            </svg>
                            <h3>128</h3>
                        </div>
                        {/* <div className="flex gap-x-1 text-white font-bold text-sm items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
                            </svg>
                            <h3>27.1K</h3>
                        </div> */}
                    </div>
                </div>
            </div>
                <div className="bg-black w-full">
                    <div id="ellaavatar" className="relative -mt-12 ml-5 rounded-full bg-red-500 w-24 h-24 border-2 p-[0.3px]"></div>
                </div>
                <div className="flex flex-col z-[100] px-6 gap-y-2 pb-5 border-b bg-black pt-3">
                    <h2 className="text-white text-xl font-[500] flex items-center">Petite Nikky🌼
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 flex">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
                    </svg>
                    </h2>
                    <p className="text-gray-400 text-sm">@your.dandelion</p>
                    <p>Hii!!! ✨ im here ALOT</p>
                    <a className="text-[#01AEF0] text-sm">More info</a>
                </div>

                {/* <div className="flex flex-col z-[100] px-6 gap-y-1 pb-2 border-b bg-white pt-3 mt-2">
                    <h2 className="text-gray-500 text-md font-[500] flex items-center">SUBSCRIPTION</h2>
                    <p className="text-lg font-semibold mt-2">Limited offer - 40% off for 31 days!</p>
                    <p className="text-gray-400 text-sm">Offer ends Today 1 offers left</p>
                    <div className="bg-[#F6F7F8] flex gap-x-2 justify-end rounded-md mt-1">
                        <div className="w-fit h-fit">
                            <div id="ellaavatar" className="flex rounded-full bg-red-500 w-10 h-10 -ml-4 border-2 p-[0.3px]"></div>
                        </div>
                        <div className="py-2 flex">
                            <p className="flex">I am looking forward to getting to know about you and your fantasies😉💦 (3/50 left)</p>
                        </div>
                    </div>
                    <a className="bg-[#01AEF0] text-sm flex px-5 py-3 rounded-full w-full mt-3">
                        <p className="text-white font-[500]">SUBSCRIBE</p>
                        <p className="text-white font-[600] flex ml-auto">$3 for 31 days</p>
                    </a>
                    <p className="text-gray-400 text-sm flex mt-2">Regular price $5 /month</p>
                </div> */}

                <div className="flex flex-col z-[100] px-6 gap-y-1 pb-2 border-b bg-black pt-3 mt-2">
                    <h2 className="text-gray-500 text-md font-[500] flex items-center">SUBSCRIPTION</h2>
                    <p className="text-lg font-semibold mt-2">Limited offer - FREE Subscription!</p>
                    <p className="text-gray-400 text-sm">Offer ends Today 1 offers left</p>
                    <div className="bg-black/80 flex gap-x-2 justify-end rounded-md mt-1">
                        <div className="w-fit h-fit">
                            <div id="ellaavatar" className="flex rounded-full bg-red-500 w-10 h-10 -ml-4 border-2 p-[0.3px]"></div>
                        </div>
                        <div className="py-2 flex">
                            <p className="flex text-white">I am looking forward to getting to know about you and your fantasies😉💦 (3/50 left)</p>
                        </div>
                    </div>
                    <a className="bg-red-600 text-sm flex px-5 py-3 rounded-full w-full mt-3">
                        <p className="text-white font-[500]">SUBSCRIBE</p>
                        <p className="text-white font-[600] flex ml-auto">FREE</p>
                    </a>
                    <p className="text-gray-400 text-sm flex mt-2">Regular price $5 /month</p>
                </div>


                <div className="flex gap-x-2 justify-center mt-2 bg-black w-full border-y border-gray-200">
                    <div className="flex items-center justify-center text-black border-b-2 border-black text-sm font-[600] text-center w-full py-2">303 POSTS</div>
                    <div className="flex items-center justify-center text-[#8996A3] text-sm font-[600] text-center w-full py-2">401 MEDIA</div>
                </div>
            
        </div>
        <div className="w-1/3 h-screen px-10 hidden md:flex md:flex-col">
            {/* <div className="flex flex-col border z-[100] px-6 gap-y-1 pb-2 border-b bg-white pt-3 mt-2">
                    <h2 className="text-gray-500 text-md font-[500] flex items-center">SUBSCRIPTION</h2>
                    <p className="text-lg font-semibold mt-2">Limited offer - 40% off for 31 days!</p>
                    <p className="text-gray-400 text-sm">Offer ends Today 1 offers left</p>
                    <div className="bg-[#F6F7F8] flex gap-x-2 justify-end rounded-md mt-1">
                        <div className="w-fit h-fit">
                            <div id="ellaavatar" className="flex rounded-full bg-red-500 w-10 h-10 -ml-4 border-2 p-[0.3px]"></div>
                        </div>
                        <div className="py-2 flex">
                            <p className="flex">I am looking forward to getting to know about you and your fantasies😉💦 (3/50 left)</p>
                        </div>
                    </div>
                    <a className="bg-[#01AEF0] text-sm flex px-5 py-2 rounded-full w-full mt-3">
                        <p className="text-white font-[500]">SUBSCRIBE</p>
                        <p className="text-white font-[600] flex ml-auto">$3 for 31 days</p>
                    </a>
                    <p className="text-gray-400 text-sm flex mt-2">Regular price $5 /month</p>
            </div> */}
            <div className="flex flex-col border z-[100] px-6 gap-y-1 pb-2 border-white/20 bg-black/80 pt-3 mt-2">
                    <h2 className="text-gray-500 text-md font-[500] flex items-center">SUBSCRIPTION</h2>
                    <p className="text-lg text-white/90 font-semibold mt-2">Limited offer - FREE Subscription!</p>
                    <p className="text-gray-400 text-sm">Offer ends Today 1 offers left</p>
                    <div className="bg-black/80 flex gap-x-2 justify-end rounded-md mt-1">
                        <div className="w-fit h-fit">
                            <div id="ellaavatar" className="flex rounded-full bg-red-500 w-10 h-10 -ml-4 border-2 p-[0.3px]"></div>
                        </div>
                        <div className="py-2 flex">
                            <p className="flex text-white">I am looking forward to getting to know about you and your fantasies😉💦 (3/50 left)</p>
                        </div>
                    </div>
                    <a className="bg-red-600 text-sm flex px-5 py-2 rounded-full w-full mt-3">
                        <p className="text-white font-[500]">SUBSCRIBE</p>
                        <p className="text-white font-[600] flex ml-auto">FREE</p>
                    </a>
                    <p className="text-gray-400 text-sm flex mt-2">Regular price $5 /month</p>
            </div>
        </div>
        </div>
    </div>
  )
}

export default LandingDark
