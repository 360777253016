import React from 'react'
import OneImg from '../images/models/nikky/2024-04-10 17.33.20.jpg'

const SelectedModel = () => {
  return (
    <div className="flex w-screen h-screen bg-black overflow-hidden">
      <div className="h-screen hidden md:flex md:w-1/5">
          <ul className="flex flex-col mt-20 select-none">
              <li className="flex gap-x-4 w-full py-2 px-4 items-center">
                  <div>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                    </svg>
                  </div>
                  <p className="text-white font-bold text-sm text-center">Home</p>
              </li>
              <li className="flex gap-x-4 w-full py-2 px-4 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                </svg>

                  <p className="text-white font-bold text-sm text-center">Members</p>
              </li>
          </ul>
      </div>

      <div className="relative w-full md:w-2/5 max-h-screen border-x border-gray-100/20">
        <div className="relative aspect-w-16 aspect-h-3 md:aspect-w-16 md:aspect-h-9 max-h-screen select-none">
                <img src={OneImg} className="object-cover w-full hover:pointer-events-none max-h-[60vh] md:max-h-screen" alt="Image"/>
                <div class="absolute flex md:bottom-20 inset-0 bg-gradient-to-b from-transparent via-transparent to-black w-full h-full"></div>
                  <div className="absolute md:bottom-10 w-full flex flex-col md:justify-center md:items-center px-10 z-[30]">
                    <h3 className="flex text-white text-2xl font-thin z-[20] text-center italic justify-center">Meet</h3>
                    <h3 className="flex text-white text-4xl font-black z-[20] text-center uppercase justify-center">Nikky Dandelion</h3>
                    <a href="https://onlyfans.com/nikkydandelion/c49" className="mt-5 rounded-full border border-[#F5FA10] bg-black/50 text-[#F5FA10] w-full font-black select-none justify-center text-center text-md"><div className="flex bg-[#F5FA10]/5 justify-center px-5 py-4 rounded-full w-full h-full text-center">Up For A Chat? It's Our&nbsp;<span className="hidden md:flex">Little&nbsp;</span>Secret</div></a>
                    <h4 className="flex mt-2 text-sm text-white/80 font-normal z-[20] text-center justify-center">Exclusive peeks, flirty chats, just between us… 😉</h4>
                  </div>
           
            </div>
      </div>

    </div>
  )
}

export default SelectedModel
