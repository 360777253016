import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Sidebar from './Sidebar'
import { useParams, useNavigate } from 'react-router-dom';
import fetchUserData from '../api/userData'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


function CreatorDetails() {

  const [sender, setSender] = useState()
  const [senderId, setSenderId] = useState()
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [age, setAge] = useState('');
  const [idImg, setIdImg] = useState()
  const { creatorId } = useParams();

  useEffect(() => {
    const fetchUser = async () =>{
      const userData = await fetchUserData();

      setSenderId(userData._id)

      setSender(userData.username)
  }
  fetchUser()
  },[sender, senderId])

  const handleIdChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setIdImg(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleIdDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setIdImg(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleIdDragOver = (event) => {
    event.preventDefault();
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  
  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };
  
  const handleAgeChange = (event) => {
    setAge(event.target.value);
  };

  const registerCreator = async (e) => {
    
    e.preventDefault()

    try {
      const response = await axios.patch(`http://localhost:4000/creator/verify/${creatorId}`, {
        idImg:idImg,
        senderId:senderId,
        age:age,
        name:name,
        surname:lastName,
      });

      console.log(response.data); // Newly created user details


      // Redirect to a dashboard or home page
      window.location.href = '/'; // Assuming you have a dashboard page with URL '/dashboard'
      
    } catch (error) {
      console.error(error); // Error message from the server
    }
  };


    return(
      
      <div className="flex">

        <div className="flex w-1/4 h-screen fixed">
        <Sidebar/>
        </div>

        <div className="min-h-screen h-screen py-5 w-3/4 flex flex-col ml-auto justify-center">

          <div className="h-full w-full flex flex-col gap-y-3 bg-white pb-8 pt-4 px-4 sm:px-10">
            <h1 className="text-center text-3xl font-extrabold dark:text-white text-darkmodeblack">You are <span className="text-[#3BBDF8]">One Step Away</span> from Becoming a Creator</h1>
            <p className="flex mx-auto">Verify Your Identity to Get Verified!</p>
            <form onSubmit={(e)=>registerCreator(e)} className="space-y-5">
              <div className="flex flex-col w-full items-center gap-y-3">
              {/* <label htmlFor="banner" className="block text-sm font-medium text-gray-700">
                  Banner image
              </label> */}
                <div className="flex flex-col w-full">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Name*
                  </label>
                  <div className="mt-1">
                    <input
                      id="name"
                      name="name"
                      type="text"
                      autoComplete="off"
                      value={name}
                      onChange={handleNameChange}
                      required
                      className="appearance-none relative block w-1/3 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-2xl focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                      placeholder="Enter your name"
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                    Last Name*
                  </label>
                  <div className="mt-1">
                    <input
                      id="lastName"
                      name="lastName"
                      type="text"
                      autoComplete="off"
                      value={lastName}
                      onChange={handleLastNameChange}
                      required
                      className="appearance-none relative block w-1/3 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-2xl focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                      placeholder="Enter your last name"
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full">
                    <label htmlFor="age" className="block text-sm font-medium text-gray-700">
                      Date of Birth*
                    </label>
                    <DatePicker
                    id="age"
                    name="age"
                    selected={age} // Pass the state value here
                    onChange={(date) => setAge(date)} // Update the state with selected date
                    required
                    showYearDropdown
                    dateFormat="yyyy-MM-dd"
                    className="border border-gray-300 rounded-2xl py-2 px-3 w-1/3"
                    placeholder="Select your birth date"
                  />
                  </div>
                <div className="relative h-[200px] w-full overflow-hidden rounded-lg mt-10" onDrop={handleIdDrop}  onDragOver={handleIdDragOver}>
                <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                    Upload The Photo of You next to Your Id / Passport / Driving License*
                  </label>
                  <label for="banner" class="flex inset-0 items-center justify-center w-full h-full cursor-pointer transition bg-slate-300 hover:bg-slate-400 text-white font-bold py-14 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400">
                    {!idImg && (
                    <p>Upload an image</p>
                    )}
                    {idImg && (
                    <img src={idImg} alt="Uploaded Banner" className="w-full h-auto" />
                  )}
                    <input type="file" id="banner" name="banner" accept="image/*" onChange={handleIdChange} className="sr-only"/>
                  </label>        
                </div>
                <p className="flex mt-1 mr-auto text-xs text-simpleblack">Make sure all the information in the image are Clearly Visible!</p>
              </div>
           
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-[#3abef8] hover:bg-[#22c7ff] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Finish Verification
                </button>
              </div>

            </form>
          </div>
      </div>
    </div>
    )
}

export default CreatorDetails
