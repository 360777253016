import axios from 'axios';
import React from 'react'

const fetchUserData = async () => {
  try {
    const response = await axios.get('http://localhost:4000/user', {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user data', error);
    return null;
  }
};

export default fetchUserData;
