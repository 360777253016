import React, {useState} from 'react'
import axios from 'axios'
import Sidebar from './Sidebar'

function Signup() {


  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [age, setAge] = useState('')
  const [gender, setGender] = useState('')

  const handleCheckboxChange = (event) => {
    setGender(event.target.value);
    console.log(gender)
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    console.log(username)
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    console.log(email)
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    console.log(password)
  };


  const registerUser = async (e) => {
    
    e.preventDefault()

    try {
      const response = await axios.post('http://localhost:4000/users/register', {
        username: username,
        email: email,
        password: password,
        gender: gender,
      });

      console.log(response.data); // Newly created user details


      // Redirect to a dashboard or home page
      window.location.href = '/'; // Assuming you have a dashboard page with URL '/dashboard'

    } catch (error) {
      console.error(error); // Error message from the server
    }
  };


    return(
    <div className="bg-black flex w-screen h-full">
      <div className="bg-gradient-to-r bg-gray-400/20 min-h-screen w-screen ml-auto flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-white">Lips Emoji</h2>
        <div className="flex justify-center mt-8">
          <div className="flex rounded-full bg-gray-400/20 w-fit select-none">
            <a href="/login" className="flex text-xs rounded-full px-3 py-2 text-white font-semibold">Login</a>
            <div className="flex text-xs rounded-full bg-white h-fit px-3 py-2 font-semibold">Sign Up</div>
          </div>
        </div>
        {/* <p className="mt-2 text-center text-md text-gray-100 max-w">
          Or{' '}
          <a href="#" className="font-medium text-white hover:text-gray-300">
            start your 14-day free trial
          </a>
        </p> */}
      </div>

      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="py-4 px-4 sm:rounded-lg sm:px-10">
          <form onSubmit={(e)=>registerUser(e)} id="" className="">
            <div className="flex flex-col mt-1 bg-gray-300/20 rounded-md">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                onChange={handleEmailChange}
                required
                className="appearance-none text-xl bg-transparent border-b border-black relative block w-full px-20 py-4 placeholder-gray-500 placeholder:text-center placeholder:font-bold placeholder-bold text-white rounded-md focus:outline-none focus:z-10 sm:text-sm"
                placeholder="Email"
              />
              <input
                id="username"
                name="username"
                type="username"
                autoComplete="username"
                onChange={handleUsernameChange}
                required
                className="appearance-none text-xl bg-transparent border-b border-black relative block w-full px-20 py-4 placeholder-gray-500 placeholder:text-center placeholder:font-bold placeholder-bold text-white rounded-md focus:outline-none focus:z-10 sm:text-sm"
                placeholder="Username"
              />
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  onChange={handlePasswordChange}
                  required
                  className="appearance-none text-xl bg-transparent relative block w-full px-20 py-4 placeholder-gray-500 placeholder:text-center placeholder:font-bold text-white rounded-md focus:outline-none focus:z-10 sm:text-sm"
                  placeholder="Enter your password"
                />
              </div>

              {/* <div>
                <label htmlFor="gender" className="block text-sm font-medium text-gray-700">
                   I am a
                </label>
                <div className="mt-1 flex place-items-center gap-5">
                 <div className="flex place-items-center gap-2">
                     <input
                      id="checked"
                      name="gender"
                      type="checkbox"
                      value="male"
                      checked={gender === 'male'}
                      onChange={handleCheckboxChange}
                      className="rounded relative block w-[20px] h-[20px] px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    />
                    <label>Male</label>
                  </div>

                  <div className="flex place-items-center gap-2">
                    <input
                      id="checked"
                      name="gender"
                      type="checkbox"
                      value="female"
                      checked={gender === 'female'}
                      onChange={handleCheckboxChange}
                      className="rounded relative block w-[20px] h-[20px] px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    />
                    <label>Female</label>
                  </div>

                  <div className="flex place-items-center gap-2">
                    <input
                      id="checked"
                      name="gender"
                      type="checkbox"
                      value="other"
                      checked={gender === 'other'}
                      onChange={handleCheckboxChange}
                      className="rounded relative block w-[20px] h-[20px] px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    />
                    <label>Other</label>
                  </div>
                </div>
                
              </div> */}

            <div className="flex mt-4 justify-end items-center">
              {(email && username && password) && (
              <button
                type="submit"
                className="flex gap-x-1 items-center font-bold w-full flex justify-center py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-black bg-white focus:outline-none"
              >
                Sign Up
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="w-4 h-4 justify-center items-center">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                    </svg>
              </button>
              )}
              { (email=='' || username=='' || password=='') && (
              <button
                type="submit"
                className="opacity-[60%] flex gap-x-1 items-center font-bold w-full flex justify-center py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-black bg-white focus:outline-none"
              >
                Sign Up
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="w-4 h-4 justify-center items-center">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                    </svg>
              </button>
              )}
            </div>

        

          </form>
        </div>
      </div>
    </div>
    </div>
    )
}

export default Signup
