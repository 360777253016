import React, {useState} from 'react'
import axios from 'axios'
import Sidebar from './Sidebar'

function Login() {

  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [age, setAge] = useState('')

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    console.log(username, "username")
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    console.log(email)
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    console.log(password)
  };

  // Set the token in localStorage after successful login
  const handleLogin = async (e) => {
    e.preventDefault()
    try {
      console.log(email, password, "wwwww")

      const response = await axios.post('http://localhost:4000/users/login', {
        username:username,
        password:password
       });

      console.log('Logged in successfully');
      console.log(response.data); // Response data from the server (e.g., user details, access token)


      const token = response.data.token;
      localStorage.setItem('token', token);

      // Redirect to a dashboard or home page
      window.location.href = '/'; // Assuming you have a dashboard page with URL '/dashboard'
      
      // ...
    } catch (error) {
      // Handle login error
      // ...
    }
  };


  // Attach the token to the request headers for subsequent requests
  const fetchUserData = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await axios.get('http://localhost:4000/users/profile', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const userData = response.data;
        // ...
      } catch (error) {
        // Handle authentication error
        // ...
      }
    } else {
      // Handle no token available
      // ...
    }
  };


  // Example code on the client-side
const refreshToken = async () => {
  const expiredToken = localStorage.getItem('token');

  try {
    const response = await axios.post('http://localhost:4000/users/refresh-token', { token: expiredToken });

    // Store the new token
    const newToken = response.data.token;
    localStorage.setItem('token', newToken);

    // Return the new token for further use
    return newToken;
  } catch (error) {
    // Handle token refresh error
    console.error('Error refreshing token', error);
    // Redirect to login page or perform necessary actions
  }
};

// Function to make an authenticated API request
const makeAuthenticatedRequest = async (url, options = {}) => {
  let token = localStorage.getItem('token');
  const expirationTime = localStorage.getItem('tokenExpiration');

  // Check if the token is expired or about to expire
  const isTokenExpired = expirationTime && new Date().getTime() > Number(expirationTime);
  if (isTokenExpired) {
    // Refresh the token
    token = await refreshToken();
  }

  try {
    const response = await axios(url, {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    // Handle API request errors
    console.error('Error making API request', error);
    // ...
  }
};

// Example usage of makeAuthenticatedRequest
const fetchData = async () => {
  try {
    let token = localStorage.getItem('token');
    console.log(token, "tok")
    const data = await makeAuthenticatedRequest('http://localhost:4000/api/protected-data', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`, // Replace `token` with your actual token value
      },
    });

    // Process the fetched data
    console.log(data);
  } catch (error) {
    // Handle API request errors
    console.error('Error fetching protected data', error);
    // ...
  }
};

// Call the fetchData function to make the authenticated request
fetchData();


    return(
      <div className="bg-black flex w-screen h-full">
      <div className="bg-gradient-to-r bg-gray-400/30 min-h-screen w-screen ml-auto flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-white">Lips Emoji</h2>
        <div className="flex justify-center mt-8">
          <div className="flex rounded-full bg-gray-400/20 w-fit select-none">
            <div className="flex text-xs rounded-full bg-white px-3 py-2 font-semibold">Login</div>
            <a href="/signup" className="flex text-xs rounded-full bg-transparent text-white px-3 py-2 font-semibold">Sign Up</a>
          </div>
        </div>
        {/* <p className="mt-2 text-center text-md text-gray-100 max-w">
          Or{' '}
          <a href="#" className="font-medium text-white hover:text-gray-300">
            start your 14-day free trial
          </a>
        </p> */}
      </div>

      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="py-4 px-4 sm:rounded-lg sm:px-10">
          <form onSubmit={(e)=>handleLogin(e)} id="" className="">
            <div className="flex flex-col mt-1 bg-gray-300/20 rounded-md">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                onChange={handleEmailChange}
                required
                className="appearance-none text-xl bg-transparent border-b border-black relative block w-full px-20 py-4 placeholder-gray-500 placeholder:text-center placeholder:font-bold placeholder-bold text-white rounded-md focus:outline-none focus:z-10 sm:text-sm"
                placeholder="Email"
              />
              <input
                id="username"
                name="username"
                type="username"
                autoComplete="username"
                onChange={handleUsernameChange}
                required
                className="appearance-none text-xl bg-transparent border-b border-black relative block w-full px-20 py-4 placeholder-gray-500 placeholder:text-center placeholder:font-bold placeholder-bold text-white rounded-md focus:outline-none focus:z-10 sm:text-sm"
                placeholder="Username"
              />
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  onChange={handlePasswordChange}
                  required
                  className="appearance-none text-xl bg-transparent relative block w-full px-20 py-4 placeholder-gray-500 placeholder:text-center placeholder:font-bold text-white rounded-md focus:outline-none focus:z-10 sm:text-sm"
                  placeholder="Enter your password"
                />
              </div>

            <div className="flex mt-4 justify-end items-center">
              <a className="flex hover:cursor-pointer text-blue-600 hover:text-blue-500 font-medium gap-x-1 text-sm text-white mt-4 text-center mr-auto select-none">
              Forgot password?{' '}
                </a>
              <button
                type="submit"
                className="flex gap-x-1 items-center font-bold w-fit flex justify-center py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-black bg-white focus:outline-none"
              >
                Log in
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="w-4 h-4 justify-center items-center">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                    </svg>
              </button>
            </div>

        

          </form>
        </div>
      </div>
    </div>
    </div>

    )
}

export default Login
