import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import logo from '../images/visitclub.gif'
import logo2 from '../images/visitclub.png'
import video from '../images/lips.mp4'
import Info from './Info';

const Landing2 = () => {

    const [clickId, setClickId] = useState("");
    const [campaign, setCampaign] = useState("");
    const [creativeId, setCreativeId] = useState("");
    const [campaignId, setCampaignId] = useState("");
    const [referrer, setReferrer] = useState("");


    const location = useLocation();
  
    // Extract click ID and key from URL parameters
    const params = new URLSearchParams(location.search);
    // const clickId = params.get('click_id');
    // const key = params.get('key');

    // const postbackUrl = `https://tsyndicate.com/api/v1/cpa/action?clickid=${clickId}&key=${key}`;
    // const postbackUrl = `https://tsyndicate.com/api/v1/cpa/action?clickid=${clickId}&key=0ZQqHn41aEhUfIUzZbdRj531Ea18t18iQOyo`
    const constructedURL = `https://www.chatnbang.com/ella?click_id=YOUR_CLICK_ID&campaign=YOUR_CAMPAIGN&creative_id=YOUR_CREATIVE_ID&campaign_id=YOUR_CAMPAIGN_ID&referrer=YOUR_REFERRER`;
    
    const url = `https://www.chatnbang.com/nikky?click_id=${clickId}&campaign=${campaign}&creative_id=${creativeId}&campaign_id=${campaignId}&referrer=${referrer}`

    useEffect(() => {
        // Function to extract URL parameters
        const getUrlParams = () => {

            const urlParams = new URLSearchParams(window.location.search);
            const clickIdParam = urlParams.get('click_id');
            const campaignParam = urlParams.get('campaign');
            const creativeIdParam = urlParams.get('creative_id');
            const campaignIdParam = urlParams.get('campaign_id');
            const referrerParam = urlParams.get('referrer');

            setClickId(clickIdParam);
            setCampaign(campaignParam);
            setCreativeId(creativeIdParam);
            setCampaignId(campaignIdParam);
            setReferrer(referrerParam);

            //http://localhost:3000/ella?click_id=123&campaign=my_campaign&creative_id=456&campaign_id=789&referrer=example.com
            //https://www.chatnbang.com?click_id=123&campaign=my_campaign&creative_id=456&campaign_id=789&referrer=example.com

        };
    
        // Function to upload data to MongoDB
        const uploadToMongoDB = (data) => {

            const urlParams = new URLSearchParams(window.location.search);
            const clickIdParam = urlParams.get('click_id');
            const campaignParam = urlParams.get('campaign');
            const creativeIdParam = urlParams.get('creative_id');
            const campaignIdParam = urlParams.get('campaign_id');
            const referrerParam = urlParams.get('referrer');


            // const apiUrl = 'http://localhost:4000/api/upload'; 
            // const apiUrl = 'https://www.chatnbang.com/api/upload'; 
            const apiUrl = 'https://srv498730.hstgr.cloud:4001/api/upload'

            axios.post(apiUrl, {
                click_id: clickIdParam,
                campaign: campaignParam,
                creative_id: creativeIdParam,
                campaign_id: campaignIdParam,
                referrer: referrerParam
            }, {
                withCredentials: true
              })
                .then(response => {
                console.log('Data uploaded successfully:', response.data);
                })
                .catch(error => {
                console.error('Error uploading data:', error);
            });
        };
    
        // Extract URL parameters and upload to MongoDB
        getUrlParams();
        const params = { click_id: clickId, campaign:campaign, creative_id: creativeId, campaign_id: campaignId, referrer:referrer };
        uploadToMongoDB(params);
      }, [location.search]); // Empty dependency array ensures this effect runs only once after initial render

    // // Send GET request to the S2S Postback URL
    // axios.get(postbackUrl)
    // .then((response) => {
    //     // Handle the response from the postback URL if needed
    //     console.log('Postback request successful:', response.data);
    // })
    // .catch((error) => {
    //     console.error('Postback request failed:', error);
    //     // Handle request failure if needed
    // });

    // const requestData = {
    //     click_id: clickId,
    //     key: key,
    //     // Add other user information as needed
    //     // For example:
    //     // name: 'John Doe',
    //     // email: 'john@example.com',
    //     // age: 30,
    //     // etc.
    //   };

    // axios.get('http://localhost:4000/aduser', {
    //     params: requestData // Pass request parameters here
    //   })
    //     .then((response) => {
    //         // Handle the response from the protected route
    //         setData(response.data);
    //         console.log(response.data)
    //     })
    //     .catch((error) => {
    //         console.error('Request failed', error);
    //         // Handle request failure or redirect to login page
    // });
    
  return (
    <div className="flex flex-col w-full h-full">
        <div className="w-full h-screen bg-white flex flex-col py-6">
            <div className="flex flex-col w-full h-fit justify-center items-center">
                <h1 className="flex text-black font-bold outline outline-[#F5FA0C] h-fit text-xl text-center select-none w-fit justify-center">CHAT'N'BANG</h1>
            </div>
            <div className="flex mt-6 h-full">
                <div className="flex flex-col bg-black w-full h-full px-10 py-20 md:px-20 md:py-60">
                    <h2 className="text-slate-300 md:text-white font-semibold text-3xl italic select-none text-left">Are you in The</h2>
                    <h2 className="text-4xl md:text-6xl not-italic font-bold text-white select-none">CHAT'N'BANG</h2>
                    <h2 className="flex gap-x-2 w-full text-4xl not-italic font-bold text-white ml-auto mr-32"><span className="flex w-full h-full bg-[#F5FA0C]"></span>Club?</h2>

                    {/* <img src={logo2} className="w-[170px] h-[65px]"/> */}
                    <p className="font-bold text-white mt-5 select-none md:max-w-[70%]">Your one way stop to chat to the world's most beautiful and interesting women.</p>
                    <a className="py-2 px-4 rounded-full font-bold bg-white w-fit h-fit mt-5 select-none flex items-center gap-x-2 hover:cursor-pointer">Visit The Club 
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="w-4 h-4 justify-center items-center">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                    </svg>
                    </a>
                    <p className="font-bold text-white mt-5 select-none max-w-[70%]">Already a member? <span className="font-normal underline hover:cursor-pointer"><a href="/login">Log in</a></span></p>
                </div>
                <div className="hidden md:flex items-center justify-center flex-col bg-black w-full h-full px-20 py-30 border-l border-gray-100/10">
                    <video src={video} width="400" height="200" autoPlay="true" loop="true" muted/>
                </div>
            </div>
        </div>
        <div className="justify-center text-center w-full h-full">
            <Info/>
        </div>
        <section class="bg-black py-6 md:py-20">
    <div class="mx-auto px-2 md:container">
        <div class="rounded-3xl border-4 border-white/5 p-2 text-white lg:mx-9 lg:rounded-[40px] lg:border-8 lg:p-3 h-full">
            <div class="flex flex-col gap-y-10 overflow-hidden rounded-2xl bg-white/5 p-4 lg:rounded-3xl lg:p-12 h-full w-full gap-y-0">
                <div class="flex flex-col gap-y-2">
                    <div class="font-headline text-2xl font-semibold text-white lg:text-5xl select-none">Inside The <span className="font-black underline decoration-[#F5FA0C]">CHAT'N'BANG</span> Club:</div>
                </div>
                <section class="mx-auto my-4 flex max-w-full flex-col justify-normal gap-x-10 gap-y-4 overflow-hidden md:my-14 md:max-w-[1000px] md:flex-row md:justify-between md:gap-y-0">
                    <div class="flex w-full items-start justify-center text-center md:h-auto md:items-center md:text-left">
                        <div>
                            <div class="flex h-full w-full flex-col items-center justify-center gap-y-6 md:items-start">
                                <div class="relative">
                                    <img class="!relative w-full" src="https://cdn.centerfold.com/images/home/phone-background.png" alt="iPhone"/>
                                    <div class="absolute top-0 h-full w-full bg-contain bg-no-repeat">
                                        <img alt="Personalized Interactions" src="https://cdn.centerfold.com/images/home/personalized-interactions-screen.png"/>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex w-full max-w-md flex-row items-center justify-center text-center md:flex-col md:text-left">
                        <div class="flex max-w-full flex-col items-center gap-y-4 md:items-start">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={0.5} stroke="currentColor" className="w-20 h-20">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.068.157 2.148.279 3.238.364.466.037.893.281 1.153.671L12 21l2.652-3.978c.26-.39.687-.634 1.153-.67 1.09-.086 2.17-.208 3.238-.365 1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                            </svg>
                            <h2 class="text-white text-3xl font-semibold select-none">Personalized Interactions</h2>
                            <p class="font-semibold text-gray-400 select-none">Enjoy a private conversation with the most beautiful and interesting women live on the site.</p>
                            <div className="flex w-fit"><a href="/explore" className="flex w-full h-full font-bold text-md rounded-full px-5 py-2 border border-white select-none">Connect</a></div>
                        </div>
                    </div>
                </section>

                
                <section class="mx-auto my-10 flex max-w-[1000px] flex-col justify-normal overflow-hidden md:h-auto md:flex-row md:justify-between hidden md:block">
   <div class="flex h-screen w-full items-center justify-center gap-x-10 md:h-auto">
      <div class="flex w-full max-w-md flex-row items-center justify-center md:flex-col">
         <div class="flex max-w-full flex-col gap-y-6">
            <div class="relative z-0">
               <div class="relative flex items-center">
               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={0.5} stroke="currentColor" className="w-20 h-20">
                <path strokeLinecap="round" strokeLinejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z" />
                </svg>
               </div>
            </div>
            <div class="flex max-w-full flex-col gap-y-2">
               <h2 class="text-white text-3xl font-semibold select-none">Intimate Live Streams</h2>
               <div class="flex flex-col gap-y-4">
               <p class="font-semibold text-gray-400 select-none">Enjoy a private conversation with the most beautiful and interesting women live on the site.</p>
                  <div><a href="/explore" class="inline-flex items-center focus:outline-none focus-visible:outline-blue-500 tracking-normal pointer-events-auto text-sm border active:bg-white/10 border-white font-semibold none py-2 px-4 rounded-full">
                      <span class="text-center"><span>View Live Streams</span></span></a></div>
               </div>
            </div>
         </div>
      </div>
      <div class="z-1">
         <div class="flex h-full w-full flex-col items-center justify-center gap-y-6 md:items-start">
            <div class="relative">
               <img class="!relative w-full" src="https://cdn.centerfold.com/images/home/phone-background.png" alt="iPhone"/>
               <div class="absolute top-0 h-full w-full bg-contain bg-no-repeat"><img alt="Intimate Live Streams" src="https://cdn.centerfold.com/images/home/livestream-screen.png"/></div>
            </div>
         </div>
      </div>
   </div>
</section>

                <section class="mx-auto my-4 flex max-w-full flex-col justify-normal gap-x-10 gap-y-4 overflow-hidden md:my-8 md:max-w-[1000px] md:flex-row md:justify-between md:gap-y-0">
    <div class="flex w-full items-start justify-center text-center md:h-auto md:items-center md:text-left">
        <div>
            <div class="flex h-full w-full flex-col items-center justify-center gap-y-6 md:items-start">
                <div class="relative">
                    <img class="!relative w-full" src="https://cdn.centerfold.com/images/home/phone-background.png" alt="iPhone"/>
                    <div class="absolute top-0 h-full w-full bg-contain bg-no-repeat">
                        <img alt="Intimate Live Streams" src="https://cdn.centerfold.com/images/home/exclusive-content-screen.png"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex w-full max-w-md flex-row items-center justify-center text-center md:flex-col md:text-left">
        <div class="flex max-w-full flex-col gap-y-2">
        <h2 class="text-white text-3xl font-semibold select-none">Exclusive Content</h2>
            <div class="flex flex-col gap-y-4">
                <p class="font-semibold text-gray-400 select-none">Unlock never-before-seen photos and videos from your favorite Chat'N'Bang Creators.</p>
                <div className="flex justify-center">
                <div className="flex w-fit justify-center"><a href="/explore" className="flex w-full h-full font-bold text-md rounded-full px-5 py-2 border border-white select-none">Unlock Content</a></div>
                </div>
            </div>
        </div>
    </div>
</section>

            </div>
        </div>
    </div>
</section>

    </div>
  )
}

export default Landing2
