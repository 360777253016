import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import socketIOClient from 'socket.io-client';
import Drawer from './Drawer'
import fetchUserData from '../api/userData'
import axios from 'axios'
import { BaseMessageSignerWalletAdapter } from '@solana/wallet-adapter-base';
import Sidebar from './Sidebar';

const ENDPOINT = 'http://localhost:4000';


function Chat() {
    const navigate = useNavigate();
    const { conversationId } = useParams();
    const [messages, setMessages] = useState([]);
    const [messageInput, setMessageInput] = useState('');
    const [conversationId2, setConversationId2] = useState(null); // Initialize conversationId as null
    const [conversations, setConversations] = useState([]);
    const [sender, setSender] = useState()
    const [senderId, setSenderId] = useState()
    const [recipient, setRecipient] = useState('')
    const [justOpened, setJustOpened] = useState(true)
    const [socket, setSocket] = useState(null);
    const [conversationNum, setConversationNum] = useState(0);
    const [usernames, setUsernames] = useState([])
    const [selected, setSelected] = useState(0)


  useEffect(() => {
    const socket = socketIOClient(ENDPOINT, { transports: ['websocket'] });
    setSocket(socket);


    const scrollToBottom = () => {
      const container = document.querySelector('.chat-container');
      container.scrollTop = container.scrollHeight;
    };

     // Listen for new messages
     socket.on('message', (message) => {
      // Update state with the new messag
      conversations[conversationNum].messages.push(message);

      console.log(conversations[conversationNum].messages)
      setConversations(conversations)

      scrollToBottom();
    });

    socket.on('join', () => {
          // // JavaScript code to start at the bottom of the container
          // const container = document.querySelector('.chat-container');

          // // Set the initial scroll position to the bottom
          // container.scrollTop = container.scrollHeight;
            // Scroll to bottom after the state update
        scrollToBottom();
    })

    if(usernames && justOpened==true){
      setJustOpened(false);
      setRecipient(usernames[0])
      if(conversations.length>0 && !conversationId){
      navigate(`/chat/${conversations[0]._id}`)
      }
    }


    if(conversationId) {
      // Joining a conversation room
      socket.emit('join', conversationId);
    }

    // if(conversations && justOpened==false) {
    //   setRecipient('')
    // }


    const fetchUser = async () =>{
        const userData = await fetchUserData();

        setSenderId(userData._id)

        setSender(userData.username)

        fetchConversations(userData.username);
    }
    fetchUser()

    // Fetch conversations
    const fetchConversations = async (sender) => {
    try {
        const response = await axios.get('http://localhost:4000/conversations',{
          headers: {
            Authorization: localStorage.getItem('token'),
        },
        params: {
          sender: sender,
        },
        });
        // console.log(response.data.conversations, "Reres")
        // console.log(response.data.usernames, "usernames")
        setConversations(response.data.conversations);
        setUsernames(response.data.usernames)
    } catch (error) {
        console.error('Error fetching conversations:', error);
    }
    };
    
    // Receive message from the server
    socket.on('messageReceived', (message) => {
    setMessages((prevMessages) => [...prevMessages, message]);

    setTimeout(scrollBottom, 3000)

    function scrollBottom(){
    // JavaScript code to start at the bottom of the container
    const container = document.querySelector('.chat-container');

    // Set the initial scroll position to the bottom
    container.scrollTop = container.scrollHeight;
    }
    });

    socket.on('conversationCreated', (message) =>{
      navigate(`/chat/${message.conversationId}`)
    })

    // // Fetch message history when joining a conversation
    // socket.emit('joinConversation', sender, recipient); // Replace with the actual conversation ID

     // Handle message history received from the server
     socket.on('messageHistory', (messageHistory) => {
        setMessages(messageHistory);
      });

      socket.on('conversationCreated', (conversationId2) => {
    
        console.log("wwww")
        // console.log(conversationId2, "IDDIDID")
        // navigate(`/chat/${conversationId2}`);
      });

    // Clean up the socket connection when the component unmounts
    // return () => {
    //   socket.disconnect();
    // };

    // Clean up socket connection
    // socket.off('chat message');

    // Clean up the socket connection when the component unmounts
    return () => {
    socket.disconnect();
    };
  },[usernames,conversations,conversationId,conversationId2]);

  const handleMessageChange = (event) => {
    setMessageInput(event.target.value);
  };

  const sendMessage = async (message) => {

    console.log(message)
  
    try {

         // Generate new conversationId if it's not initialized
         const socket = socketIOClient(ENDPOINT, { transports: ['websocket'] });

        // Sending a message
        socket.emit('message', {
          conversationId: conversationId,
          content: message.content,
          sender: message.sender,
          recipient: message.recipient
        });

         // JavaScript code to start at the bottom of the container
         const container = document.querySelector('.chat-container');

         // Set the initial scroll position to the bottom
         container.scrollTop = container.scrollHeight;

        await axios.post('http://localhost:4000/message/send', message);

        // const socket = socketIOClient(ENDPOINT, { transports: ['websocket'] });

        // socket.emit('sendMessage', message, (conversationId) => {
        //     console.log('Message sent. Conversation ID:', conversationId);
        // });

        //  Wait for the POST request to finish, then emit the socket event
        // await new Promise((resolve) => socket.emit('sendMessage', message, resolve));


      } catch (error) {
        console.error('Error sending message:', error);
      }
  };
 

  const handleSubmit = async(event, selectedRecipient) => {

    event.preventDefault();

    console.log('submitted')

    const userData = await fetchUserData();

    setSender(userData.username)

    // Check if conversationId is already initialized
    if (recipient) {
     
   // const joinConversation = async () => {
        //   const socket = socketIOClient(ENDPOINT, { transports: ['websocket'] });
        //   try {
        //     const response = await new Promise((resolve) => {
        //       socket.emit('joinConversation', sender, recipient, (response) => {
        //         resolve(response.responseConversationId); // Resolve the promise after receiving the conversation ID
        //       });
        //     });
        //     setConversationId(response);
        //   } catch (err) {
        //     console.log(err);
        //   }
        // };

        if (!conversationId) {
            // Generate new conversationId if it's not initialized
        const socket = socketIOClient(ENDPOINT, { transports: ['websocket'] });

  

        const joinConversation = async () => {
          const socket = socketIOClient(ENDPOINT, { transports: ['websocket'] });

          // // Joining a conversation room
          // socket.emit('join', conversationId);
       
            return new Promise((resolve, reject) => {
            socket.emit('joinConversation', sender, recipient, async(response) => {
              console.log(response, "response")
              // setConversationId(response.responseConversationId);
                console.log(response.responseConversationId, "response convert")
                resolve(response.responseConversationId);
              })
          });
        };

        const sendMessageWithConversationId = async (conversationId) => {
          const message = {
            conversationId: conversationId,
            sender: userData.username,
            recipient: recipient,
            content: messageInput,
          };
      
          sendMessage(message);
      
          // Clear the input field
          setMessageInput('');
        };

        const handleSendMessage = async () => {
          const conversationId = await joinConversation();
          socket.emit('conversationCreated', conversationId.toString());
          navigate(`/chat/${conversationId}`)
          console.log(conversationId, "convoid");
      
          sendMessageWithConversationId(conversationId);

        };
      
        handleSendMessage();
      
        // });
        } else {

          
        
            const message = {
                conversationId: conversationId,
                sender: userData.username,
                recipient: recipient,
                content: messageInput,
            };

            sendMessage(message);

            // Clear the input field
            setMessageInput('');
        }
    } else if (selectedRecipient) {
     
      // const joinConversation = async () => {
           //   const socket = socketIOClient(ENDPOINT, { transports: ['websocket'] });
           //   try {
           //     const response = await new Promise((resolve) => {
           //       socket.emit('joinConversation', sender, recipient, (response) => {
           //         resolve(response.responseConversationId); // Resolve the promise after receiving the conversation ID
           //       });
           //     });
           //     setConversationId(response);
           //   } catch (err) {
           //     console.log(err);
           //   }
           // };
   
           if (!conversationId) {
               // Generate new conversationId if it's not initialized
           const socket = socketIOClient(ENDPOINT, { transports: ['websocket'] });
   
     
   
           const joinConversation = async () => {
             const socket = socketIOClient(ENDPOINT, { transports: ['websocket'] });
   
             // // Joining a conversation room
             // socket.emit('join', conversationId);
          
               return new Promise((resolve, reject) => {
               socket.emit('joinConversation', sender, selectedRecipient, async(response) => {
                 console.log(response, "response")
                 // setConversationId(response.responseConversationId);
                   console.log(response.responseConversationId, "response convert")
                   resolve(response.responseConversationId);
                 })
             });
           };
   
           const sendMessageWithConversationId = async (conversationId) => {
             const message = {
               conversationId: conversationId,
               sender: userData.username,
               recipient: selectedRecipient,
               content: messageInput,
             };
         
             sendMessage(message);
         
             // Clear the input field
             setMessageInput('');
           };
   
           const handleSendMessage = async () => {
             const conversationId = await joinConversation();
             socket.emit('conversationCreated', conversationId.toString());
             navigate(`/chat/${conversationId}`)
             console.log(conversationId, "convoid");
         
             sendMessageWithConversationId(conversationId);
   
           };
         
           handleSendMessage();
         
           // });
           } else {
   
             
           
               const message = {
                   conversationId: conversationId,
                   sender: userData.username,
                   recipient: selectedRecipient,
                   content: messageInput,
               };
   
               sendMessage(message);
   
               // Clear the input field
               setMessageInput('');
           }
       } else{
        console.log('Recipient not selected');
    }
  };

//   const sendMessage = () => {
//     const message = {
//       senderId: 'yourSenderId', // Replace with the actual sender ID
//       recipientId: 'yourRecipientId', // Replace with the actual recipient ID
//       content: messageInput,
//     };

//     // Send the message
//     socket.emit('message', message);

//     // Clear the message input
//     setMessageInput('');
//   };

const handleRecipientChange = (event) => {
    setRecipient(event.target.value);
    console.log(recipient)
  };

  const scrollBottom = () => {
     // JavaScript code to start at the bottom of the container
     const container = document.querySelector('.chat-container');

     // Set the initial scroll position to the bottom
     container.scrollTop = container.scrollHeight;
  }

  // console.log(conversations[0].lastMessage.content, "CONVERSAT")
  // console.log(conversations, "MSGS")

  return (
    <>

    <div className="flex w-screen h-screen pb-40">
    {/* <Drawer/> */}

    <div className="flex w-1/4 h-screen fixed">
      <Sidebar/>
      </div>

         {/* Chat List */}
         <div className="flex flex-col w-1/4 bg-white border-r select-none ml-auto">
          
          <div className="py-6 text-center">
            <h1 className="text-2xl font-bold text-[#3ABEF8]">Chats</h1>
          </div>

              {/* Add recipient input */}
            <div className="flex w-full items-center justify-center mb-4 px-3">
              <input
                type="text"
                value={recipient}
                onChange={handleRecipientChange}
                placeholder="Recipient"
                className="border border-gray-300 rounded mr-2 p-2 w-full"
              />
              <button
                type="button"
                onClick={handleSubmit}
                className="text-white p-2 rounded"
              >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
              </svg>

              </button>
          </div>

          {conversations && usernames && (
          <ul className="flex-grow overflow-auto text-black pl-3">
           
                {conversations.map((conversation, index) => (
                  <li
                    onClick={() => {
                      setConversationNum(index)
                      navigate(`/chat/${conversation._id}`)
                      // setRecipient(usernames[index])
                      setSelected(index)
                      scrollBottom()
                    }}
                    key={conversation._id}
                    className="pr-4 pl-2 py-2 hover:bg-gray-100 cursor-pointer"
                  >
                    <div className="flex items-center">
                      <img
                        src="https://source.unsplash.com/random/50x50"
                        alt="Profile"
                        className="w-10 h-10 rounded-full"
                      />
                    
                    {/* {conversation.lastMessage.content} */}
                      <div className="flex-grow ml-4 w-full">
                        {usernames && (
                        <div className="w-full">
                          <div>
                          <p className="font-semibold">{usernames[index]}</p>
                          </div>  
                        </div>
                        )}
                        {/* <h2 className="text-lg font-medium">{conversation.participants[0]}</h2> */}
                        <p className="text-sm text-gray-500">
                        {conversation.lastMessage && conversation.lastMessage.content}
                          {/* {conversation.lastMessage.content} */}
                        </p>
                      </div>
                      <div className="text-sm text-gray-500">
                        {/* <p>{conversation.lastMessage.timestamp}</p> */}
                        <div className="bg-blue-500 h-2 w-2 rounded-full"></div>
                      </div>
                    </div>
                  </li>
                ))}
          </ul>
          )}
        </div>
            {/* chatting app */}
            <div className="w-full pb-6 pl-6 flex flex-col md:w-2/4 rounded-lg bg-white shadow-lg">
              <div className="flex gap-x-2 py-4 items-center">
                <div className="w-10 h-10 bg-blue-400 rounded-full">
                  
                </div>
                <p className="font-bold">
                {usernames[selected]}
                </p>
              </div>
            <div className="chat-container h-full overflow-y-auto mb-6 pr-6">
                {conversations && conversations.length>0 && (
                <div>
                {conversations[conversationNum].messages.map((message, index) => (
                <div
                    key={index}
                    className={`flex items-center mb-2 ${
                      message.senderId != senderId ? 'justify-start' : 'justify-end'
                    }`}
                >
                    <div
                    className={`p-2 rounded-lg ${
                        message.senderId == senderId ? 'bg-[#3BBDF8] text-white' : 'bg-gray-300 text-white'
                    }`}
                    >
                    {message.content}
                    </div>
                </div>
                ))} 
                </div>
                )}
            </div>
            <form onSubmit={(e)=>handleSubmit(e, usernames[selected])} className="flex">
                <input
                type="text"
                value={messageInput}
                onChange={handleMessageChange}
                placeholder="Type a message..."
                className="flex-grow border border-gray-300 rounded-l-full rounded-r-full focus:outline-none p-2"
                />
                <button
                type="submit"
                className=" text-white px-4 py-2 rounded-r-lg"
                >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="w-6 h-6 transition-all hover:opacity-50">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                </svg>

                </button>
            </form>

             {/* Add recipient input
          <div className="flex items-center mb-4">
            <input
              type="text"
              value={recipient}
              onChange={handleRecipientChange}
              placeholder="Recipient"
              className="border border-gray-300 rounded p-2 mr-2"
            />
            <button
              type="button"
              onClick={handleSubmit}
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
            >
              Start Conversation
            </button>
          </div> */}
            
            </div>
        </div>
    </>
  );
}

export default Chat;
