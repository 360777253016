import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './main.css';
import './main.css'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import App from './App';
import Section from './components/Section';
import Finder from './components/Finder';
import Tip from './components/Tip';
import Articles from './components/Articles';
import Disclaimer from './components/Disclaimer';
import Featured from './components/Featured';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import PicSell from './components/PicSell';
import Login from './components/Login';
import ChatApp from './components/ChatApp';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import PrivateRoute from './auth/privateRoute';
import UserProfile from './components/UserProfile'
import LiveMessenger from './components/LiveMessenger';
import OnlyfansFinder from './components/OnlyfansFinder';
import OnlyfansFinderNew from './components/OnlyfansFinderNew';
import OnlyfansFinderFree from './components/OnlyfansFinderFree';
import Creator from './components/Creator';
import Home from './components/Home';
import CreatorDetails from './components/CreatorDetails';
import CreatePost from './components/Upload';
import CreatorProfile from './components/CreatorProfile';
import Settings from './components/Settings';
import Upload from './components/Upload';
import Next from './components/Next';
import Gifs from './components/Gifs';
import Landing from './components/Landing';
import LandingDark from './components/LandingDark';
import FinderDark from './components/FinderDark';
import FinderPage from './components/FinderPage';
import Landing2 from './components/Landing2';
import Signup from './components/Signup';
import Member from './components/Member';
import Models from './components/Models';
import SelectedModel from './components/SelectedModel';

// const PrivateRoute = ({ element: Element, ...rest }) => {
//   const isAuthenticated = !!localStorage.getItem('token');

//   return isAuthenticated ? (
//     <Element {...rest} />
//   ) : (
//     <Navigate to="/login" replace />
//   );
// };

const AppRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/18" element={<Disclaimer className="scroll-smooth" />} />
      <Route path="/" element={<Home className="scroll-smooth" />} />
      <Route path="/gifs" element={<Gifs className="scroll-smooth" />} />
      <Route path="/live" element={<Section className="scroll-smooth" />} />
      <Route path="/featured" element={<Featured className="scroll-smooth" />} />
      <Route path="/finder" element={<Finder className="scroll-smooth" />} />
      <Route path="/finder/onlyfans" element={<OnlyfansFinder className="scroll-smooth" />} />
      <Route path="/finder/onlyfans-new" element={<OnlyfansFinderNew className="scroll-smooth" />} />
      <Route path="/finder/onlyfans-free" element={<OnlyfansFinderFree className="scroll-smooth" />} />
      <Route path="/premium" element={<PicSell className="scroll-smooth" />} />
      <Route path="/donate" element={<Tip className="scroll-smooth" />} />
      <Route path="/login" element={<Login className="scroll-smooth" />} />*/}
      <Route exact path="/finder" element={<OnlyfansFinder className="scroll-smooth" />} />
      <Route path="/finder/:criteria" element={<FinderPage/>} />
      {/* <Route path="/finder" element={<Finder className="scroll-smooth" />} /> */}
      <Route path="/signup" element={<Signup className="scroll-smooth" />} />
      <Route path="/" element={<Landing2 className="scroll-smooth" />} />
      <Route path="/explore" element={<Models className="scroll-smooth" />} />
      <Route path="/member" element={<Member className="scroll-smooth" />} />
      <Route path="/dc2" element={<LandingDark className="scroll-smooth" />} />
      <Route path="/login" element={<Login className="scroll-smooth" />} />
      <Route path="/gifs" element={<Home className="scroll-smooth" />} />
      <Route path="/model/nikky" element={<SelectedModel className="scroll-smooth" />} />

      {/* <Route path='/' element={<PrivateRoute/>}>
        <Route path='/dashboard' element={<Dashboard/>}/>
        <Route path='/user' element={<Settings/>}/>
        <Route path='/chat/:conversationId' element={<LiveMessenger className="scroll-smooth" />} />
        <Route path='/chat' element={<LiveMessenger className="scroll-smooth" />} />
        <Route path="/creator" element={<Creator className="scroll-smooth" />} />
        <Route path="/creator/verify" element={<CreatorDetails className="scroll-smooth" />} />
        <Route path="/upload" element={<Upload className="scroll-smooth" />} />
        <Route path="/upload/next" element={<Next className="scroll-smooth" />} />
        <Route path="/create" element={<CreatePost className="scroll-smooth" />} />
        <Route path="/creator/verify/:creatorId" element={<CreatorDetails className="scroll-smooth" />} />
        <Route path="/creator/:username" element={<CreatorProfile className="scroll-smooth" />} />
      </Route> */}

    </Routes>
  );
};

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ChakraProvider>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>
);


// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import './main.css';
// import App from './App';
// import './main.css'
// import { ChakraProvider } from '@chakra-ui/react'
// import { BrowserRouter, Routes, Route, Navigate, Link } from "react-router-dom";
// import Search from './components/Search';
// import { useLocation } from "react-router-dom";
// import Section from './components/Section';
// import Rankings from './components/Rankings';
// import Tip from './components/Tip';
// import Articles from './components/Articles';
// import Disclaimer from './components/Disclaimer';
// import Featured from './components/Featured';
// import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
// import PicSell from './components/PicSell';
// import Login from './components/Login';
// import ChatApp from './components/ChatApp';
// import Register from './components/Register';
// import Dashboard from './components/Dashboard';


// // // Custom PrivateRoute component for authenticated routes
// // const PrivateRoute = ({ element, ...rest }) => {
// //   const isAuthenticated = localStorage.getItem('token'); // Check if the user is authenticated
  
// //   return isAuthenticated ? (
// //     <Route {...rest} element={element} />
// //   ) : (
// //     <Navigate to="/login" /> // Redirect to the login page if not authenticated
// //   );
// // };

// const PrivateRoute = ({ element: Element, ...rest }) => {
//   const isAuthenticated = !!localStorage.getItem('token');

//   return isAuthenticated ? (
//     <Element {...rest} />
//   ) : (
//     <Navigate to="/login" replace />
//   );
// };


// const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(
//   <>
//     <React.StrictMode>

//       <BrowserRouter>
//         <Routes>
//           <Route path='/18' element={<Disclaimer className="scroll-smooth"/>}/>
//           <Route path='/' element={<App className="scroll-smooth"/>}/>
//           <Route path='/live' element={<Section className="scroll-smooth"/>}/>
//           <Route path='/featured' element={<Featured className="scroll-smooth"/>}/>
//           <Route path='/articles' element={<Articles className="scroll-smooth"/>}/>
//           <Route path='/rankings' element={<Rankings className="scroll-smooth"/>}/>
//           <Route path='/premium' element={<PicSell className="scroll-smooth"/>}/>
//           <Route path='/donate' element={<Tip className="scroll-smooth"/>}/>
//           <Route path='/login' element={<Login className="scroll-smooth"/>}/>
//           <Route path='/register' element={<Register className="scroll-smooth"/>}/>
//           <Route path='/chat' element={<ChatApp className="scroll-smooth"/>}/>
//           <Route path='/dashboard' element={<Dashboard className="scroll-smooth"/>}/>
//           {/* <PrivateRoute path="/dashboard" element={<Dashboard className="scroll-smooth" />} /> */}
//         </Routes>
//       </BrowserRouter>
//     </React.StrictMode>
//   </>
// );