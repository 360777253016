import React, {useEffect, useState} from 'react';
import axios from 'axios'
import Navbar from './Navbar';
import Drawer from './Drawer'
import fetchUserData from '../api/userData'
import SwitcherNav from './SwitcherNav'
import { set } from 'date-fns/esm';

const SidebarMini = () => {

    const [data, setData] = useState();
    const [sender, setSender] = useState();
    const [senderId, setSenderId] = useState();
    const [conversations, setConversations] = useState([]);
    const [usernames, setUsernames] = useState([]);
    const [userData, setUserData] = useState(null);

    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [age, setAge] = useState('')
    const [gender, setGender] = useState('')
    const [bannerImage, setBannerImage] = useState(null);
    const [profilePicture, setProfilePicture] = useState(null);
    const [bio, setBio] = useState('');
    const [user, setUser] = useState()
    const [creator, setCreator] = useState()
    const [toggle,setToggle] = useState(false)

  useEffect(() => {
    // Function to fetch user data
    const fetchUserData = async () => {
      try {
        // Make a request to the API endpoint to fetch user data
        const response = await axios.get('http://localhost:4000/user', {
          headers: {
            Authorization: localStorage.getItem('token'),
        },
        });

        if(response.data){
            console.log(response.data)
            setUser(response.data)
            setUsername(response.data.displayName)
            setBio(response.data.bio)
            setSenderId(response.data.senderId)
            setSender(response.data.username)

            window.localStorage({sender:sender})

        }

        // Set the user data in the state
        // setUserData(response.data);
        return userData;
        
      } catch (error) {
        console.error('Error fetching user data', error);
      }
    };

    // Call the function to fetch user data
    fetchUserData();

    const fetchCreator = async()=>{

        const response = await axios.get(`http://localhost:4000/api/check-creator?userId=${senderId}`);
    
          if(response.data){
            console.log(response.data)
            setCreator(response.data)
            setUsername(response.data.displayName)
            setBio(response.data.bio)
          }
      }
    
      if(senderId){
      fetchCreator()
      }

  }, [sender, senderId]);

    // const fetchUser = async () =>{
    //     const userData = await fetchUserData();

    //     setSender(userData.username)

    //   }
    //   fetchUser()
      
  return (
    <div className="w-fit px-2 h-full flex bg-tabublack">
      {toggle==false&&(
        <div className="flex fixed h-screen w-fit items-center content-center justify-center bg-white">
          <div className="flex w-fit h-fit items-center content-center justify-center dark:bg-gray-400 py-6 px-2 rounded-md text-white bg-indigo-700 -ml-1 font-bold">
            <div onClick={()=>setToggle(true)} className="flex w-fit h-fit">&gt;&gt;</div>
          </div>
      </div>
      )}
      {toggle==true&&(
      <div className="flex flex-col py-8 w-full px-3 border-r border-gray-100/10 items-center">
              <div className="flex w-full h-fit">
              {!sender && (
                  <div className="flex gap-x-2 w-full h-fit justify-center">
                      <a href="/login" className="flex w-fit h-fit px-2 bg-[#3BBDF8]/90 dark:bg-red-600 shadow-md hover:bg-[#3BBDF8] text-white font-semibold select-none">Log in</a>
                      <a href="/register" className="flex w-fit px-2 bg-slate-100 opacity-80 hover:opacity-100 text-darkmodeblack font-semibold select-none">Register</a>
                  </div>
              )}
              {sender && !user.pfp && (
            <div className="justify-center select-none w-[40px] h-[40px] items-center justify-center flex gap-x-3 mt-2 rounded-full bg-gray-300/30 border dark:border-none py-1 px-1">
                <p className="text-[#3BBDF8] dark:text-white font-bold text-lg capitalize">{sender[0]}</p>
            </div>
              )}

            {sender && user.pfp && (
            <div className="justify-center select-none w-[40px] h-[40px] items-center justify-center flex gap-x-3 mt-2 rounded-full bg-gray-300/30 border dark:border-none">
                <img className="w-full h-full rounded-full" src={user.pfp}/>
            </div>
              )}
              </div>

            <ul className="flex flex-col justify-center items-center gap-y-3 w-full mt-10 bg-base-100 dark:bg-tabublack/90 text-base-content">
          
            <div className="flex flex-col justify-center items-center gap-y-3">
              <li className="flex w-full">
                <a className="text-sm flex flex-col items-center py-2 h-full w-full  dark:hover:bg-ytnighttitle/10 hover:text-[#3BBDF8] dark:text-gray-400 dark:hover:text-darkmodewhite font-medium hover:cursor-pointer" href="/">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                </svg>
                Home</a>
              </li>
              <li className="flex w-full">
                <a className="text-sm flex flex-col items-center py-2 w-full h-full  dark:hover:bg-ytnighttitle/10 hover:text-[#3BBDF8] dark:text-gray-400 dark:hover:text-darkmodewhite font-medium hover:cursor-pointer" href="/chat">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                </svg>
                Messages</a>
              </li>
              {/* <li>
                <a className="flex flex-col items-center py-2 h-full  font-medium text-gray-600 hover:text-[#3BBDF8] dark:text-gray-400 dark:hover:bg-ytnighttitle/10 dark:hover:text-gray-200 hover:cursor-pointer" href="/premium">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                </svg>
                Get Tokens</a>
              </li> */}
              {/* <li>
                <a className="h-full dark:hover:bg-ytnighttitle/10 hover:text-[#3BBDF8] dark:text-gray-400 dark:hover:text-darkmodewhite font-medium" href="/featured">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                </svg>
                  Featured</a>
              </li> */}
              {/* <li>
                <a className="text-sm flex flex-col items-center py-2 w-full h-full  dark:hover:bg-ytnighttitle/10 hover:text-[#3BBDF8] dark:text-gray-400 dark:hover:text-darkmodewhite font-medium hover:cursor-pointer" href="/finder">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                </svg>
                  Finder</a>
              </li> */}

              <li className="">
                <a className="text-sm flex flex-col items-center py-3 px-3 hover:stroke-darkmodewhite hover:text-darkmodewhite shadow-white w-full h-full dark:hover:bg-ytnighttitle/5 hover:text-[#3BBDF8] stroke-white dark:from-red-600 dark:shadow-lg dark:shadow-red-600 dark:hover:stroke-amber-600 hover:stroke-[#3BBDF8] bg-gradient-to-b from-blue-500 to-sky-400 rounded-lg dark:text-gray-400 dark:hover:text-darkmodewhite font-medium hover:cursor-pointer" href="/upload">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
                </a>
              </li>
              {/* <li>
                <a className="text-sm flex flex-col items-center py-2 w-full h-full font-medium text-gray-600 hover:text-[#3BBDF8] dark:text-gray-400  dark:hover:bg-ytnighttitle/10 dark:hover:text-gray-200 hover:cursor-pointer" href="/promote">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z" />
                  </svg>
          
                Promote</a>
              </li> */}
              {/* <li>
                <a className="h-full dark:hover:bg-ytnighttitle/10 hover:text-[#3BBDF8] dark:text-gray-400 dark:hover:text-darkmodewhite font-medium" href="/articles">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
                </svg>
                Articles</a>
              </li> */}
              {sender && (
                <li className="flex w-full">
                  <a className="text-sm flex flex-col items-center py-2 px-4 w-full h-full font-medium text-gray-600 hover:text-[#3BBDF8] dark:text-gray-400  dark:hover:bg-ytnighttitle/10 dark:hover:text-gray-200 hover:cursor-pointer" href="/user">
                  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  Account</a>
                </li>
                )}

              {!creator && (
              <li className="flex w-full">
                <a className="text-sm text-center flex flex-col items-center py-2 w-full h-full font-medium text-gray-600 hover:text-[#3BBDF8] dark:text-gray-400  dark:hover:bg-ytnighttitle/10 dark:hover:text-gray-200 hover:cursor-pointer" href="/creator">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                </svg>
                <span className="w-[80%]">Become a Creator</span></a>
              </li>
              )}

              <li className="flex w-full">
                <div className="text-sm text-center flex flex-col items-center py-2 w-full h-full font-medium text-gray-600 hover:text-[#3BBDF8] dark:text-gray-400  dark:hover:bg-ytnighttitle/10 dark:hover:text-gray-200 hover:cursor-pointer">
                <SwitcherNav/>
                <span className="w-full">Theme</span></div>
              </li>
            
            </div>
            </ul>
            {/* 
            <div>
              <a className="flex rounded-full py-4 px-5 items-center font-bold text-white bg-[#3BBDF8] hover:text-[#3BBDF8] dark:text-gray-400  dark:hover:bg-ytnighttitle/10 dark:hover:text-gray-200 hover:cursor-pointer" href="/post/create">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
              </svg>
              Create a Post</a>
            </div> */}
        </div>
      )}
      </div>
  )
}

export default SidebarMini
