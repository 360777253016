import React from 'react';

const PostDetailView = ({ post }) => {
  return (
    <div className="post-detail-view">
      <img
        src={post.media[0]}
        alt={`Post Image`}
        className="max-w-full max-h-80vh object-contain mb-4 z-[200]"
      />
      {/* <div className="text-center">
        <h2 className="text-2xl font-semibold">{post.title}</h2>
        <p className="mt-2">{post.content}</p>
      </div> */}
    </div>
  );
};

export default PostDetailView;
