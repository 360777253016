import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Sidebar from './Sidebar'
import fetchUserData from '../api/userData'
import SidebarMini from './SidebarMini'

function Creator() {

  const [sender, setSender] = useState()
  const [senderId, setSenderId] = useState()
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [age, setAge] = useState('')
  const [gender, setGender] = useState('')
  const [bannerImage, setBannerImage] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [bio, setBio] = useState('');
  const [instagramLink, setInstagramLink] = useState('');
  const [redditLink, setRedditLink] = useState('')
  const [twitterLink, setTwitterLink] = useState('');
  const [pornhubLink, setPornhubLink] = useState('');
  const [websiteLink, setWebsiteLink] = useState('');
  const [creator, setCreator] = useState()
  const [price, setPrice] = useState(0)

  useEffect(() => {

    const fetchUser = async () =>{
      const userData = await fetchUserData();

      setSenderId(userData._id)

      setSender(userData.username)
  }
  fetchUser()

  const fetchCreator = async()=>{

    const response = await axios.get(`http://localhost:4000/api/check-creator?userId=${senderId}`);

      if(response.data){
        console.log(response.data)
        setCreator(response.data)
        setUsername(response.data.displayName)
        setBio(response.data.bio)
        setPrice(response.data.price)
        setInstagramLink(response.data.socialMedia[0].link)
        setTwitterLink(response.data.socialMedia[1].link)
        setWebsiteLink(response.data.socialMedia[2].link)
      }
  }

  if(senderId){
  fetchCreator()
  }

  },[sender, senderId])

  const handleBioChange = (event) => {
    setBio(event.target.value);
    console.log(bio);
  };

  const handleBannerChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setBannerImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBannerDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setBannerImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBannerDragOver = (event) => {
    event.preventDefault();
  };

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfilePicture(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleProfilePictureDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfilePicture(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleProfilePictureDragOver = (event) => {
    event.preventDefault();
  };

  const handleCheckboxChange = (event) => {
    setGender(event.target.value);
    console.log(gender)
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    console.log(username)
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    console.log(email)
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    console.log(password)
  };


  const registerCreator = async (e) => {
    
    e.preventDefault()

    try {
      const response = await axios.post('http://localhost:4000/users/register-creator', {
        displayName: username,
        banner: bannerImage,
        pfp: profilePicture,
        bio: bio,
        instagram: instagramLink,
        twitter: twitterLink,
        website: websiteLink,
        senderId: senderId,
      });

      console.log(response.data._id); // Newly created user details


      // Redirect to a dashboard or home page
      window.location.href = `/creator/verify/${response.data._id}`; // Assuming you have a dashboard page with URL '/dashboard'
      
    } catch (error) {
      console.error(error); // Error message from the server
    }
  };

  const handleInstagramChange = (e) => {
    setInstagramLink(e.target.value)
  };

  const handleRedditChange = (e) => {
    setRedditLink(`${e.target.value}`)
  };

  const handlePornhubChange = (e) => {
    setPornhubLink(`${e.target.value}`)
  };

  const handleTwitterChange = (e) => {
    setTwitterLink(e.target.value)
  };

  const handleWebsiteChange = (e) => {
    setWebsiteLink(e.target.value)
  };

  const handlePriceChange = (e) => {
    setPrice(e.target.value)
    console.log(price)
  };

  const updateCreator = async (e) => {
    
    e.preventDefault()
    
    try {
      const response = await axios.put(`http://localhost:4000/api/update-creator/${creator._id}`, {
        displayName: username,
        banner: bannerImage,
        pfp: profilePicture,
        bio: bio,
        instagram: instagramLink,
        twitter: twitterLink,
        website: websiteLink,
        senderId: senderId,
        price:price,
      });

      console.log(response.data._id); // Newly created user details


      // Redirect to a dashboard or home page
      window.location.href = `/`; // Assuming you have a dashboard page with URL '/dashboard'
      
    } catch (error) {
      console.error(error); // Error message from the server
    }
  };

    return(
      
      <div className="flex">

        <div className="flex w-1/7 h-screen">
        <SidebarMini/>
        </div>

        {creator && (
          <div className="min-h-screen h-full py-5 mx-auto w-full flex flex-col justify-center">
            <div className="h-full w-full flex flex-col gap-y-5 bg-white pb-8 pt-4 px-4 sm:px-10">
              <h1 className="text-center text-3xl font-extrabold dark:text-white text-darkmodeblack">Become <span className="text-[#3BBDF8]">a</span> Creator</h1>
              <form onSubmit={(e)=>updateCreator(e)} className="space-y-6">
                <div className="flex flex-col items-center justify-start">
                {/* <label htmlFor="banner" className="block text-sm font-medium text-gray-700">
                    Banner image
                </label> */}
                    <div className="relative h-[200px] w-full overflow-hidden rounded-lg border-2" onDrop={handleBannerDrop} onDragOver={handleBannerDragOver}>
                  <label for="banner" class="flex inset-0 items-center justify-center w-full h-full cursor-pointer transition bg-slate-300 hover:bg-slate-400 text-white font-bold py-14 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400">
                    {!bannerImage && (
                    <img src={creator.bannerImage} alt="Uploaded Banner" className="w-full h-auto" />
                  )}
                    {bannerImage && (
                      <img src={bannerImage} alt="Uploaded Banner" className="w-full h-auto" />
                    )}
                    <input type="file" id="banner" name="banner" accept="image/*" onChange={handleBannerChange} className="sr-only"/>
                  </label>        
                </div>

                  <div className="flex mr-auto ml-7 -mt-14 h-[100px] w-[100px] overflow-hidden rounded-full z-10 border-2 border-white" onDrop={handleProfilePictureDrop} onDragOver={handleProfilePictureDragOver}>
                  <label for="pfp" class="relative flex inset-0 items-center justify-center w-[100px] h-[100px] border-2 cursor-pointer transition bg-slate-300 hover:bg-slate-400 text-white font-bold rounded-full focus:outline-none overflow-hidden focus:ring-2 focus:ring-blue-400">
                    {!profilePicture && (
                      <img src={creator.profilePicture} alt={`Profile Picture of ${creator.username}`} className="w-full h-full" />
                    )}
                    {profilePicture && (
                      <img src={profilePicture} alt={`Profile Picture of ${creator.username}`} className="w-full h-full" />
                    )}
                       <input type="file" id="pfp" name="pfp" accept="image/*" onChange={handleProfilePictureChange} className="sr-only"/>
                  </label>
                  </div>
                </div>
                <div className="flex w-full h-fit">
                  <div>
                    <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                      Display name (username)*
                    </label>
                    <div className="mt-1">
                      <input
                        id="displayName"
                        name="displayName"
                        type="text"
                        autoComplete="off"
                        value={username}
                        onChange={handleUsernameChange}
                        required
                        className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-2xl focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                        placeholder="Enter your display name"
                      />
                    </div>
                  </div>
                  
                </div>
                <div>
                  <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                    Add a Bio*
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="bio"
                      name="bio"
                      autoComplete="off" // Turn off autocomplete for textarea
                      onChange={handleBioChange}
                      value={bio}
                      required
                      className="resize-y appearance-none relative block w-2/3 pb-[100px] px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-2xl focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                      placeholder="Enter your bio"
                      rows={4} // Set the number of rows to start with
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-y-2">
                  <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                    Links
                  </label>
                  <div className="mt-1 w-2/3">
                    <div class="relative flex-1 items-center">
                      <label for="website" class="flex items-center w-[112px] absolute left-4 top-[10px] text-body-md text-gray-500 dark:text-alpha-light-60 border-r border-gray-200 dark:border-alpha-light-10">Website</label>
                    <input
                    value={websiteLink}
                    onChange={handleWebsiteChange}
                    type="text" formcontrolname="website" placeholder="https://yourwebsite.com" input="" id="website" class="pl-[144px] pr-16 ng-untouched ng-pristine ng-valid border border-gray-200 dark:bg-gray-50/10 dark:border-transparent dark:disabled:placeholder:text-gray-500 dark:disabled:text-gray-500 dark:focus:ring-gray-600 dark:placeholder:text-gray-400 dark:text-gray-50 disabled:bg-gray-50 disabled:border-transparent disabled:cursor-ot-allowed disabled:placeholder:text-gray-400 disabled:text-gray-400 error:border-transparent error:ring-2 error:ring-red-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:shadow-[0px_0px_0px_4px_rgba(97,_114,_243,_0.1)] placeholder:text-gray-500 px-4 py-2 rounded-2xl text-body-md text-gray-900 transition-shadow w-full"/>
                    </div>
                  </div>
                  <div className="mt-1 w-2/3">
                    <div class="relative flex-1 items-center">
                      <label for="x" name="website" class="flex items-center w-[112px] absolute left-4 top-[10px] text-body-md text-gray-500 dark:text-alpha-light-60 border-r border-gray-200 dark:border-alpha-light-10">X (ex. Twitter)</label>
                    <input
                    value={twitterLink}
                    onChange={handleTwitterChange}
                    type="text" name="twitter" formcontrolname="x" placeholder="https://twitter.com" input="" id="x" class="pl-[144px] pr-16 ng-untouched ng-pristine ng-valid border border-gray-200 dark:bg-gray-50/10 dark:border-transparent dark:disabled:placeholder:text-gray-500 dark:disabled:text-gray-500 dark:focus:ring-gray-600 dark:placeholder:text-gray-400 dark:text-gray-50 disabled:bg-gray-50 disabled:border-transparent disabled:cursor-ot-allowed disabled:placeholder:text-gray-400 disabled:text-gray-400 error:border-transparent error:ring-2 error:ring-red-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:shadow-[0px_0px_0px_4px_rgba(97,_114,_243,_0.1)] placeholder:text-gray-500 px-4 py-2 rounded-2xl text-body-md text-gray-900 transition-shadow w-full"/>
                    </div>
                  </div>
                  <div className="mt-1 w-2/3">
                    <div class="relative flex-1 items-center">
                      <label for="instagram" class="flex items-center w-[112px] absolute left-4 top-[10px] text-body-md text-gray-500 dark:text-alpha-light-60 border-r border-gray-200 dark:border-alpha-light-10">Instagram</label>
                    <input
                    value={instagramLink}
                    onChange={handleInstagramChange}
                    type="text" name="instagram" formcontrolname="instagram" placeholder="https://instagram.com" input="" id="instagram" class="pl-[144px] pr-16 ng-untouched ng-pristine ng-valid border border-gray-200 dark:bg-gray-50/10 dark:border-transparent dark:disabled:placeholder:text-gray-500 dark:disabled:text-gray-500 dark:focus:ring-gray-600 dark:placeholder:text-gray-400 dark:text-gray-50 disabled:bg-gray-50 disabled:border-transparent disabled:cursor-ot-allowed disabled:placeholder:text-gray-400 disabled:text-gray-400 error:border-transparent error:ring-2 error:ring-red-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:shadow-[0px_0px_0px_4px_rgba(97,_114,_243,_0.1)] placeholder:text-gray-500 px-4 py-2 rounded-2xl text-body-md text-gray-900 transition-shadow w-full"/>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col w-full h-fit justify-start">
                    <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                      Subscription
                    </label>
                    <div className="w-1/3 flex mt-3">
                      <div class="relative flex-1 items-center">
                        <label for="subscription" class="flex items-center w-[142px] absolute left-4 top-[10px] text-body-md text-gray-500 dark:text-alpha-light-60 border-r border-gray-200 dark:border-alpha-light-10">Price</label>
                      <input
                      value={price}
                      onChange={handlePriceChange}
                      type="number" formcontrolname="Price" placeholder="$0" min="5" max="999999999" input="" id="website" class="pl-[175px] pr-3 ng-untouched ng-pristine ng-valid border border-gray-200 dark:bg-gray-50/10 dark:border-transparent dark:disabled:placeholder:text-gray-500 dark:disabled:text-gray-500 dark:focus:ring-gray-600 dark:placeholder:text-gray-400 dark:text-gray-50 disabled:bg-gray-50 disabled:border-transparent disabled:cursor-ot-allowed disabled:placeholder:text-gray-400 disabled:text-gray-400 error:border-transparent error:ring-2 error:ring-red-300 focus:outline-none focus:ring-none placeholder:text-gray-500 px-4 py-2 rounded-2xl text-body-md text-gray-900 transition-shadow w-full"/>
                      </div>
                    </div>
                  </div>
                

                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-[#3abef8] hover:bg-[#22c7ff] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Save
                  </button>
                </div>

              </form>
            </div>
          </div>
        )}

        {!creator && (
          <div className="min-h-screen h-full py-5 w-3/4 flex flex-col mx-auto justify-center">

            <div className="h-full w-full flex flex-col gap-y-5 bg-white pb-8 pt-4 px-4 sm:px-10">
            <h1 className="text-center text-3xl font-extrabold dark:text-white text-darkmodeblack">Become <span className="text-[#3BBDF8]">a</span> Creator</h1>
            <form onSubmit={(e)=>registerCreator(e)} className="space-y-6">
              <div className="flex flex-col items-center">
              {/* <label htmlFor="banner" className="block text-sm font-medium text-gray-700">
                  Banner image
              </label> */}
                <div className="relative h-[200px] w-full overflow-hidden rounded-lg border-2" onDrop={handleBannerDrop} onDragOver={handleBannerDragOver}>
                  <label for="banner" class="flex inset-0 items-center justify-center w-full h-full cursor-pointer transition bg-slate-300 hover:bg-slate-400 text-white font-bold py-14 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400">
                    {!bannerImage && (
                    <p>Upload an image</p>
                    )}
                    {bannerImage && (
                    <img src={bannerImage} alt="Uploaded Banner" className="w-full h-auto" />
                  )}
                    <input type="file" id="banner" name="banner" accept="image/*" onChange={handleBannerChange} className="sr-only"/>
                  </label>        
                </div>

                <div className="flex -mt-14 -top-14 px-10 h-[100px] w-full overflow-hidden rounded-full" onDrop={handleProfilePictureDrop} onDragOver={handleProfilePictureDragOver}>
                  <label for="pfp" class="relative flex inset-0 items-center justify-center w-[100px] h-[100px] border-2 cursor-pointer transition bg-slate-300 hover:bg-slate-400 text-white font-bold rounded-full focus:outline-none overflow-hidden focus:ring-2 focus:ring-blue-400">
                    {!profilePicture && (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z" />
                      <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z" />
                    </svg>
                    )}
                    {profilePicture && (
                    <img src={profilePicture} alt="Uploaded Profile Picture" className="w-full h-full" />
                  )}
                    <input type="file" id="pfp" name="pfp" accept="image/*" onChange={handleProfilePictureChange} className="sr-only"/>
                  </label>
                </div>
              </div>
              <div>
                <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                  Display name (username)*
                </label>
                <div className="mt-1">
                  <input
                    id="displayName"
                    name="displayName"
                    type="text"
                    autoComplete="off"
                    value={username}
                    onChange={handleUsernameChange}
                    required
                    className="appearance-none relative block w-1/3 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-2xl focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    placeholder="Enter your display name"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                  Add a Bio*
                </label>
                <div className="mt-1">
                  <textarea
                    id="bio"
                    name="bio"
                    autoComplete="off" // Turn off autocomplete for textarea
                    onChange={handleBioChange}
                    required
                    className="resize-y appearance-none relative block w-2/3 pb-[100px] px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-2xl focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    placeholder="Enter your bio"
                    rows={4} // Set the number of rows to start with
                  />
                </div>
              </div>

              <div className="flex flex-col gap-y-2">
                <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                  Links
                </label>
                <div className="mt-1 w-2/3">
                  <div class="relative flex-1 items-center">
                    <label for="website" class="flex items-center w-[112px] absolute left-4 top-[10px] text-body-md text-gray-500 dark:text-alpha-light-60 border-r border-gray-200 dark:border-alpha-light-10">Website</label>
                  <input
                  value={websiteLink}
                  onChange={handleWebsiteChange}
                  type="text" formcontrolname="website" placeholder="https://yourwebsite.com" input="" id="website" class="pl-[144px] pr-16 ng-untouched ng-pristine ng-valid border border-gray-200 dark:bg-gray-50/10 dark:border-transparent dark:disabled:placeholder:text-gray-500 dark:disabled:text-gray-500 dark:focus:ring-gray-600 dark:placeholder:text-gray-400 dark:text-gray-50 disabled:bg-gray-50 disabled:border-transparent disabled:cursor-ot-allowed disabled:placeholder:text-gray-400 disabled:text-gray-400 error:border-transparent error:ring-2 error:ring-red-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:shadow-[0px_0px_0px_4px_rgba(97,_114,_243,_0.1)] placeholder:text-gray-500 px-4 py-2 rounded-2xl text-body-md text-gray-900 transition-shadow w-full"/>
                  </div>
                </div>
                <div className="mt-1 w-2/3">
                  <div class="relative flex-1 items-center">
                    <label for="x" name="website" class="flex items-center w-[112px] absolute left-4 top-[10px] text-body-md text-gray-500 dark:text-alpha-light-60 border-r border-gray-200 dark:border-alpha-light-10">X (ex. Twitter)</label>
                  <input
                  value={twitterLink}
                  onChange={handleTwitterChange}
                  type="text" name="twitter" formcontrolname="x" placeholder="https://twitter.com/" input="" id="x" class="pl-[144px] pr-16 ng-untouched ng-pristine ng-valid border border-gray-200 dark:bg-gray-50/10 dark:border-transparent dark:disabled:placeholder:text-gray-500 dark:disabled:text-gray-500 dark:focus:ring-gray-600 dark:placeholder:text-gray-400 dark:text-gray-50 disabled:bg-gray-50 disabled:border-transparent disabled:cursor-ot-allowed disabled:placeholder:text-gray-400 disabled:text-gray-400 error:border-transparent error:ring-2 error:ring-red-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:shadow-[0px_0px_0px_4px_rgba(97,_114,_243,_0.1)] placeholder:text-gray-500 px-4 py-2 rounded-2xl text-body-md text-gray-900 transition-shadow w-full"/>
                  </div>
                </div>
                <div className="mt-1 w-2/3">
                  <div class="relative flex-1 items-center">
                    <label for="instagram" class="flex items-center w-[112px] absolute left-4 top-[10px] text-body-md text-gray-500 dark:text-alpha-light-60 border-r border-gray-200 dark:border-alpha-light-10">Instagram</label>
                  <input
                  value={instagramLink}
                  onChange={handleInstagramChange}
                  type="text" name="instagram" formcontrolname="instagram" placeholder="https://instagram.com/" input="" id="instagram" class="pl-[144px] pr-16 ng-untouched ng-pristine ng-valid border border-gray-200 dark:bg-gray-50/10 dark:border-transparent dark:disabled:placeholder:text-gray-500 dark:disabled:text-gray-500 dark:focus:ring-gray-600 dark:placeholder:text-gray-400 dark:text-gray-50 disabled:bg-gray-50 disabled:border-transparent disabled:cursor-ot-allowed disabled:placeholder:text-gray-400 disabled:text-gray-400 error:border-transparent error:ring-2 error:ring-red-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:shadow-[0px_0px_0px_4px_rgba(97,_114,_243,_0.1)] placeholder:text-gray-500 px-4 py-2 rounded-2xl text-body-md text-gray-900 transition-shadow w-full"/>
                  </div>
                </div>
                <div className="mt-1 w-2/3">
                  <div class="relative flex-1 items-center">
                    <label for="Reddit" class="flex items-center w-[112px] absolute left-4 top-[10px] text-body-md text-gray-500 dark:text-alpha-light-60 border-r border-gray-200 dark:border-alpha-light-10">Reddit</label>
                  <input
                  value={redditLink}
                  onChange={handleRedditChange}
                  type="text" name="reddit" formcontrolname="reddit" placeholder="https://reddit.com/u/" input="" id="reddit" class="pl-[144px] pr-16 ng-untouched ng-pristine ng-valid border border-gray-200 dark:bg-gray-50/10 dark:border-transparent dark:disabled:placeholder:text-gray-500 dark:disabled:text-gray-500 dark:focus:ring-gray-600 dark:placeholder:text-gray-400 dark:text-gray-50 disabled:bg-gray-50 disabled:border-transparent disabled:cursor-ot-allowed disabled:placeholder:text-gray-400 disabled:text-gray-400 error:border-transparent error:ring-2 error:ring-red-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:shadow-[0px_0px_0px_4px_rgba(97,_114,_243,_0.1)] placeholder:text-gray-500 px-4 py-2 rounded-2xl text-body-md text-gray-900 transition-shadow w-full"/>
                  </div>
                </div>
                <div className="mt-1 w-2/3">
                  <div class="relative flex-1 items-center">
                    <label for="instagram" class="flex items-center w-[112px] absolute left-4 top-[10px] text-body-md text-gray-500 dark:text-alpha-light-60 border-r border-gray-200 dark:border-alpha-light-10">Pornhub</label>
                  <input
                  value={pornhubLink}
                  onChange={handlePornhubChange}
                  type="text" name="pornhub" formcontrolname="pornhub" placeholder="https://pornhub.com/model" input="" id="pornhub" class="pl-[144px] pr-16 ng-untouched ng-pristine ng-valid border border-gray-200 dark:bg-gray-50/10 dark:border-transparent dark:disabled:placeholder:text-gray-500 dark:disabled:text-gray-500 dark:focus:ring-gray-600 dark:placeholder:text-gray-400 dark:text-gray-50 disabled:bg-gray-50 disabled:border-transparent disabled:cursor-ot-allowed disabled:placeholder:text-gray-400 disabled:text-gray-400 error:border-transparent error:ring-2 error:ring-red-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:shadow-[0px_0px_0px_4px_rgba(97,_114,_243,_0.1)] placeholder:text-gray-500 px-4 py-2 rounded-2xl text-body-md text-gray-900 transition-shadow w-full"/>
                  </div>
                </div>
              </div>

              

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-[#3abef8] hover:bg-[#22c7ff] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Continue
                </button>
              </div>

            </form>
          </div>
        </div>
        )}

        
    </div>
    )
}

export default Creator
