import axios from 'axios';
import React, {useEffect, useState} from 'react'
import Navbar from './Navbar';
import Navigate from './Navigate';
import Drawer from './Drawer';
import Sidebar from './Sidebar';
import SidebarMini from './SidebarMini';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SwitcherNav from './SwitcherNav';

const FinderPage = () => {

    const [userListJSON, setData] = useState()
    const [min, setMin] = useState()
    let [checkmark, setCheckmark] = useState()
    let [starlink, setStarlink] = useState('http://')
    const [users, setUsers] = useState()

    let [filter, setFilter] = useState(false)

    let [usernameFilter, setUsernameFilter] = useState(false)


    let [tagsFilter, setTagsFilter] = useState('')
    let [tagsInput, setTagsInput] = useState([])

    let [titleFilter, setTitleFilter] = useState(false)

    let [search, setSearch] = useState()
    let [usernameVal, setUsernameVal] = useState('')

    let [genderFilter, setGenderFilter] = useState()
    let [genderInput, setGenderInput] = useState('')
    let [genderValue, setGenderValue] = useState()

    let [empty, setEmpty] = useState(true)

    let [genPut, setGenPut] = useState('')

    let [mainGender, setMainGender] = useState()
    let [mainGM, setMainGM] = useState(false)

    let [sortedData, setSortedData] = useState()
    let [sortable, setSortable] = useState([])

    let [value, setValue] = useState('')
    

    const [fetchUsers, setFetchUsers] = useState()

    const [rank, setRank] = useState()

    const [used, setUsed] = useState(false)

    const [models, setModels] = useState([]);

    const [username, setUsername] = useState([])


    // const [tags, setTags] = useState([])
    const [searchText, setSearchText] = useState('');
    const [filteredSearch, setFilteredSearch] = useState([]);

    const [searchResult, setSearchResult] = useState([])
    const [wasSearched, setWasSearched] = useState(false)
    const { criteria } = useParams();
    const navigate = useNavigate();


    // const [selectedSearch, setSelectedCategory] = useState([]);
    
    
    console.log("initializing");



    useEffect(() => {

      async function findUsers(){
        await axios.post('https://www.backend.chatnbang.com/api/find', { criteria: criteria })
        .then(response => {
          console.log(response.data)
          setModels(response.data);
        })
        .catch(error => {
          console.error('Error fetching models:', error);
        });
      }
      findUsers()


    }, [])


     // console.log(userListJSON, "lili")
     const handleSearchChange = (e) => {
      setSearchText(e.target.value);
    };

    function searchNew(criteria) {

      if (criteria.trim() !== '') {
        navigate(`/finder/${encodeURIComponent(criteria)}`);
      }
    }

  return (
    
    <>
    <div className="h-full w-screen flex dark:bg-tabublack bg-[#F0F3F7]">
      {/* <div className="flex w-1/8 h-screen">
      <SidebarMini/>
      </div> */}
      <div className="flex absolute w-fit h-fit top-7 left-8">
        <SwitcherNav/>
      </div>
 
    <div className="flex w-full ml-auto h-full min-h-screen py-10 px-10 dark:bg-tabublack/90 bg-[#F0F3F7]">
     
        <div className="w-full h-full flex flex-col gap-y-10 justify-center">
          <div className="flex flex-col w-full gap-y-2">
            <a href="/promote" className="px-2 py-1 flex bg-indigo-700 dark:bg-transparent dark:outline-amber-500 dark:outline dark:hover:bg-amber-500 hover:bg-[#36afe7] transition-all duration-200 w-fit rounded-md text-white select-none font-semibold ml-auto">
            
              Promote
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="flex ml-1 w-6 h-6 stroke-amber-400 fill-rose-500">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z" />
              </svg>
            </a>
            <h1 className="font-bold text-5xl text-center italic dark:text-darkmodewhite/80 select-none"> <span className="text-indigo-700 dark:text-red-600 dark:outline-red-600">OnlyFans</span> Finder</h1>
          </div>

          <div className="flex justify-center w-full gap-x-4 select-none">
            <a href="/finder/top" className="flex gap-x-1 dark:text-white dark:hover:text-[#3BBDF8] hover:opacity-90 hover:text-[#3BBDF8] dark:hover:text-amber-500 duration-200 transition-all hover:cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
              </svg>
              <p className="font-bold">Top</p>
            </a>
            <a href="/finder/new" className="flex gap-x-1 dark:text-white dark:hover:text-[#3BBDF8] hover:opacity-90 hover:text-[#3BBDF8] dark:hover:text-amber-500 duration-200 transition-all hover:cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.05 4.575a1.575 1.575 0 10-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 013.15 0v1.5m-3.15 0l.075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 013.15 0V15M6.9 7.575a1.575 1.575 0 10-3.15 0v8.175a6.75 6.75 0 006.75 6.75h2.018a5.25 5.25 0 003.712-1.538l1.732-1.732a5.25 5.25 0 001.538-3.712l.003-2.024a.668.668 0 01.198-.471 1.575 1.575 0 10-2.228-2.228 3.818 3.818 0 00-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0116.35 15m.002 0h-.002" />
              </svg>
              <p className="font-bold">New</p>
            </a>
            <a href="/finder/free" className="flex gap-x-1 dark:text-white dark:hover:text-[#3BBDF8] hover:opacity-90 hover:text-[#3BBDF8] dark:hover:text-amber-500 duration-200 transition-all hover:cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
              </svg>
              <p className="font-bold">Free</p>
            </a>
          </div>

          <div className="w-full h-fit flex gap-x-3">
            <div className="w-fit h-fit p-3 rounded-full dark:bg-slate-600/10 bg-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="flex w-4 h-4 dark:stroke-white stroke-indigo-700">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
              </svg>
            </div>
            <div className="w-fit items-center justify-center flex gap-x-2 text-indigo-700 font-semibold dark:text-white/80 select-none">
              <a href="/finder">Finder</a>
              <p className="font-normal">></p>
              <a href="/finder/onlyfans">Onlyfans</a>
              <p className="font-normal">></p>
              <a className="capitalize">{criteria}</a>
            </div>
          </div>

        <form onSubmit={()=>searchNew(searchText)} className="flex w-full h-fit items-center gap-y-3">
            <input
          type="text"
          placeholder="Search profiles..."
          value={searchText}
          onChange={handleSearchChange}
          className="pl-2 w-full py-3 rounded-l-md focus:outline-none dark:bg-slate-600/10 dark:text-white/80"
          />
            <span className="flex rounded-r-md border-indigo-700 bg-indigo-700 dark:bg-amber-500"><button type="submit" className="rounded-r-md text-white p-3 font-bold">Find</button></span>
          </form>

          {/* <h2 className="font-bold text-2xl select-none text-[#2B2B34] dark:text-darkmodewhite/90">Showing <span className="text-indigo-700 dark:text-red-600">TOP</span> OnlyFans Profiles</h2> */}

          <div className="w-full h-full flex flex-col gap-y-4 py-5 px-5 select-none bg-white dark:bg-slate-600/10">
          <h2 className="font-bold text-xl select-none text-[#2B2B34] dark:text-darkmodewhite/90"><span className="text-indigo-700 dark:text-red-600 capitalize">{criteria}</span> OnlyFans Profiles</h2>
          <div className="flex flex-col px-10">
          {models.map((model, index) => (
            <a key={model._id} className="w-full min-h-[200px] px-28 mt-4 rounded-md h-full hover:border-[#3BBDF8] duration-300 transition-all flex gap-x-4 py-5 pl-4 pr-10">
              <a href={model.onlyfans} className="w-[168px] h-[140px] flex items-center justify-center rounded-md transition-all duration-200 hover:rounded-[9px] hover:relative hover:scale-[150%]"><img alt={`image of ${model.username}`} src={model.image} className="w-[140px] h-[140px] hover:rounded-[9px] rounded-md dark:border-b dark:border-red-500 dark:shadow-md dark:shadow-red-500"/></a>
              <div className="w-full flex flex-col gap-y-4">
                <div className="flex h-full gap-x-5 select-none">
                <a href={model.onlyfans}><h2 className="h-full text-md font-bold text-[#3BBDF8] dark:text-red-600 underline hover:no-underline hover:opacity-80 transition-all duration-200">{model.username}</h2></a>
                <a href={model.onlyfans}><h2 className="h-full text-md font-bold text-slate-400/60 hover:text-slate-400 transition-all duration-200">| onlyfans > {model.username}</h2></a>
                </div>
                <div className="flex gap-x-4 items-center select-none dark:text-darkmodewhite/80">
                    <h4 className="font-normal">Photo Count: <span className="font-bold">{model.photoCount}</span></h4>
                    <h4 className="font-normal">Video Count: <span className="font-bold">{model.videoCount}</span></h4>
                    <h3 className="rounded-[9px] bg-slate-100 dark:bg-red-600 px-2 py-1 border dark:border-0 flex font-bold">{model.price}</h3>
                </div>
                { model.description.length <= 200 && (
                   <p className="text-md dark:text-darkmodewhite/80">{model.description}</p>
                )}
                { model.description.length > 200 && model.description.length <= 300 && (
                   <p className="text-sm dark:text-darkmodewhite/80">{model.description}</p>
                )}
                { model.description.length > 300 && (
                   <p className="text-xs dark:text-darkmodewhite/80">{model.description}</p>
                )}

                <div className="flex gap-x-1 w-fit">
                  { model.social1 && (
                    <a href={model.social1} className="w-[25px] h-[25px] hover:opacity-80 duration-300 transition-all">
                      {model.social1.startsWith('https://onlyfans.com/') && (
                      <div className="w-full h-full" id="onlyfans-icon"></div>
                      )}

                      {model.social1.startsWith('https://instagram.com/') && (
                      <div className="w-full h-full" id="instagram-icon"></div>
                      )}

                      {model.social1.startsWith('https://twitter.com/') && (
                      <div className="w-full h-full" id="x-icon"></div>
                      )}

                      {model.social1.startsWith('https://tiktok.com/') && (
                      <div className="w-full h-full" id="tiktok-icon"></div>
                      )}

                      {model.social1.startsWith('https://patreon.com/') && (
                      <div className="w-full h-full" id="patreon-icon"></div>
                      )}

                      {model.social1.startsWith('https://fansly.com/') && (
                      <div className="w-full h-full" id="fansly-icon"></div>
                      )}
                      
                      {model.social1.startsWith('https://pornhub.com/') && (
                      <div className="w-full h-full" id="ph-icon"></div>
                      )}
                    </a>
                  )}
                    { model.social2 && (
                    <a href={model.social2} className="w-[25px] h-[25px] hover:opacity-80 duration-300 transition-all">
                      {model.social2.startsWith('https://onlyfans.com/') && (
                      <div className="w-full h-full" id="onlyfans-icon"></div>
                      )}

                      {model.social2.startsWith('https://instagram.com/') && (
                      <div className="w-full h-full" id="instagram-icon"></div>
                      )}

                      {model.social2.startsWith('https://twitter.com/') && (
                      <div className="w-full h-full" id="x-icon"></div>
                      )}

                      {model.social2.startsWith('https://tiktok.com/') && (
                      <div className="w-full h-full" id="tiktok-icon"></div>
                      )}

                      {model.social2.startsWith('https://patreon.com/') && (
                      <div className="w-full h-full" id="patreon-icon"></div>
                      )}

                      {model.social2.startsWith('https://fansly.com/') && (
                      <div className="w-full h-full" id="fansly-icon"></div>
                      )}

                      {model.social2.startsWith('https://pornhub.com/') && (
                      <div className="w-full h-full" id="ph-icon"></div>
                      )}
                    </a>
                  )}
                   { model.social3 && (
                    <a href={model.social3} className="w-[25px] h-[25px] hover:opacity-80 duration-300 transition-all">
                      {model.social3.startsWith('https://onlyfans.com/') && (
                      <div className="w-full h-full" id="onlyfans-icon"></div>
                      )}

                      {model.social3.startsWith('https://instagram.com/') && (
                      <div className="w-full h-full" id="instagram-icon"></div>
                      )}

                      {model.social3.startsWith('https://twitter.com/') && (
                      <div className="w-full h-full" id="x-icon"></div>
                      )}

                      {model.social3.startsWith('https://tiktok.com/') && (
                      <div className="w-full h-full" id="tiktok-icon"></div>
                      )}

                      {model.social3.startsWith('https://patreon.com/') && (
                      <div className="w-full h-full" id="patreon-icon"></div>
                      )}

                      {model.social3.startsWith('https://fansly.com/') && (
                      <div className="w-full h-full" id="fansly-icon"></div>
                      )}

                      {model.social3.startsWith('https://pornhub.com/') && (
                      <div className="w-full h-full" id="ph-icon"></div>
                      )}
                    </a>
                  )}
                   { model.social4 && (
                    <a href={model.social4} className="w-[25px] h-[25px] hover:opacity-80 duration-300 transition-all">
                      {model.social4.startsWith('https://onlyfans.com/') && (
                      <div className="w-full h-full" id="onlyfans-icon"></div>
                      )}

                      {model.social4.startsWith('https://instagram.com/') && (
                      <div className="w-full h-full" id="instagram-icon"></div>
                      )}

                      {model.social4.startsWith('https://twitter.com/') && (
                      <div className="w-full h-full" id="x-icon"></div>
                      )}

                      {model.social4.startsWith('https://tiktok.com/') && (
                      <div className="w-full h-full" id="tiktok-icon"></div>
                      )}

                      {model.social4.startsWith('https://patreon.com/') && (
                      <div className="w-full h-full" id="patreon-icon"></div>
                      )}

                      {model.social4.startsWith('https://fansly.com/') && (
                      <div className="w-full h-full" id="fansly-icon"></div>
                      )}

                      {model.social4.startsWith('https://pornhub.com/') && (
                      <div className="w-full h-full" id="ph-icon"></div>
                      )}
                    </a>
                  )}
                </div>
              </div>
            </a>
          ))}
          </div>
          </div>


        </div>
        {/* <div className="md:w-[87%] sm:w-[90%] h-full gap-5 place-items-center justify-self-center items-center mx-auto font-bold -z-[20]">
  
          {userListJSON && users && starlink && rank && rank.map((element, index) => (
            <div className="overflow-x-hidden w-full -z-[1]">
            <table className="table w-full z-[1]" id="cardbg">
             
              
              <tbody className="bg-darkmodewhite">
      
                <tr className="border-indigo-200 bg-slate-800 sm:w-[80%]">
                  <th className="w-[4px]"><p className="w-fit sm:text-sm">{index+1}</p></th>
                  
                  <td className="overflow-x-hidden overflow-ellipsis">
                    <div className="flex items-center space-x-3 sm:w-[50px] md:w-[400px]">
                      <div className="avatar">
                        <div className="mask mask-squircle md:w-12 md:h-12 sm:w-9 sm:h-9" onClick={() => { click(index) }}>
                          <img src={element.image_url}/>
                        </div>
                      </div>
                      <div>

                        <div id="tokendesc" className="font-bold capitalize text-ellipsis overflow-hidden" onClick={() => { click(index) }}><p id="rankdesc" className="flex sm:text-sm md:text-lg text-ellipsis overflow-hidden">{element.username}
                        {element.num_followers>=300000 && (
                          <span class=" text-black dark:text-ytnightuser flex ml-2 overflow-ellipsis"><div id="verified" class="w-[13px] h-[13px] self-center"></div></span>
                        )}
                        {element.num_followers<300000 && (
                          <span class=" text-black dark:text-ytnightuser flex ml-2"><div class="w-[13px] h-[13px] self-center"></div></span>
                        )}</p>
                        </div>

                        <div className="text-sm opacity-50 capitalize overflow-x-hidden overflow-ellipsis w-[200px]">{element.location}</div>
                      </div>
                    </div>
                  </td>
                  <td className="sm:hidden md:w-20">
                    
                    <br/>
                    <span className="badge badge-ghost badge-sm place-self-center self-center my-auto flex overflow-ellipsis overflow-x-hidden w-[200px]">{element.current_show}</span>
                  </td>
                  <td className="sm:w-10 sm:text-sm md:text-md md:w-20"><h1 className="flex justify-center mt-2 sm:text-sm md:text-md">{element.num_followers} followers</h1>
                  <div className="flex place-content-center">
                      <button className="btn btn-ghost btn-xs flex" onClick={() => { click(index) }}>watch</button>
                    </div></td>
                  <th className="sm:w-2 md:w-2 sm:hidden">
          
                  </th>
                </tr>
           
              </tbody>
              
            </table>
       
   
          </div>


          ))}
          
        </div> */}

    </div>
    
    </div>
    </>
    
  )
}

export default FinderPage
