import React, {useEffect, useState} from 'react'
import axios from 'axios';
import { redirect, useHref } from 'react-router-dom';
import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import {MagnifyingGlassIcon, EyeIcon} from '@heroicons/react/24/solid'
import { off } from 'process';
import Drawer from './Drawer';
import Iframe from 'react-iframe'
import Warning from './Warning';
import Helmet from 'react-helmet';
import Sidebar from './Sidebar';


function ParseXml () {
  

  const [userListJSON, setData] = useState()
  const [min, setMin] = useState()
  let [checkmark, setCheckmark] = useState()
  let [starlink, setStarlink] = useState('http://')

  const [users, setUsers] = useState()

  const [bongaListJSON, setBongaData] = useState()
  const [bongaUsers, setBongaUsers] = useState()

  let [filter, setFilter] = useState(false)

  let [usernameFilter, setUsernameFilter] = useState(false)


  let [tagsFilter, setTagsFilter] = useState('')
  let [tagsInput, setTagsInput] = useState([])

  let [titleFilter, setTitleFilter] = useState(false)

  let [search, setSearch] = useState()
  let [usernameVal, setUsernameVal] = useState('')

  let [genderFilter, setGenderFilter] = useState()
  let [genderInput, setGenderInput] = useState('')
  let [genderValue, setGenderValue] = useState()

  let [empty, setEmpty] = useState(true)

  let [emptyFilters, setEmptyFilters] = useState(false)

  let [genPut, setGenPut] = useState('')

  let [mainGender, setMainGender] = useState()
  let [mainGM, setMainGM] = useState(false)

  const [tab, setTab] = useState(0)


  let [value, setValue] = useState('')
  

  const [fetchUsers, setFetchUsers] = useState()
  const [featured, setFeatured] = useState(true)
  
  console.log("initializing");

  

  useEffect(() => {

    const fetchData = async () => {
      const response = await axios.get('https://chaturbate.com/api/public/affiliates/onlinerooms/?wm=4h1tM&client_ip=request_ip');
      const userListJSON = await response.data
      //let users = userListJSON.results;

      setData(userListJSON)

      const users = await response.data.results
      setUsers(users)
    }
    fetchData()

  }, [])


  useEffect(() => {

  const fetchBongaData = async () => {
    const response = await axios.get('https://bngprm.com/promo.php?c=772324&type=api&api_v=1&limit=1000&api_type=json');
    const userListJSON = await response.data
    console.log(userListJSON, "BONGONGOGN")

    setBongaData(userListJSON)

    const bongaUsers = await response.data
    setBongaUsers(bongaUsers)
    }
    fetchBongaData()

  }, [])



  console.log("All users: ", userListJSON);

  console.log("BONGA users: ", bongaListJSON);
  console.log("BONGA username: ", bongaUsers);
  //const users = userListJSON.results;
  //let users = Object.keys(userListJSON.results);

  //console.log("users: ", users);

  let parent = document.getElementById("token_list");

  let allusers = []


  useEffect(() => {

    const fetchUsers = async() => {

      for (const i in users){
        
          
          let div = document.createElement("div");
          div.className = "token_row";

          var link = users[i].chat_room_url_revshare;

          const linkArray = link.split("://");
          const starlink = linkArray[1];
          setStarlink(starlink)

          console.log(starlink)

          if(users[i].num_followers > 300000){
            var intro = document.getElementsByClassName('checkmark');
            
            intro.setAttribute('id', 'verified')

          }

          let html = `<a class="w-full h-full" href="http://${starlink}"></a>`
  

          div.innerHTML = html;

          parent.appendChild(div);


      };

    }
    
  

  fetchUsers()

  
  },[])

    function click(params){


      console.log(params)

        var link = users[params].chat_room_url_revshare;

        

         const linkArray = link.split("://");
         const starlink = linkArray[1];
         setStarlink(starlink)

         window.open('http://'+starlink, '_blank');
      }

      function clickmaingender(params){


        console.log(params)
  
          var link = mainGender[params].chat_room_url_revshare;
  
          
  
           const linkArray = link.split("://");
           const starlink = linkArray[1];
           setStarlink(starlink)
  
           window.open('http://'+starlink, '_blank');
        }

      function clicktag(params){


        console.log(params)
  
          var link = tagsInput[params].chat_room_url_revshare;
  
          
  
            const linkArray = link.split("://");
            const starlink = linkArray[1];
            setStarlink(starlink)
  
            window.open('http://'+starlink, '_blank');
        }

        function clickusername(params){


          console.log(params)
    
            var link = search[params].chat_room_url_revshare;
    
            
    
              const linkArray = link.split("://");
              const starlink = linkArray[1];
              setStarlink(starlink)
    
              window.open('http://'+starlink, '_blank');
          }

          function clickgender(params){


            console.log(params)
      
              var link = genderFilter[params].chat_room_url_revshare;
      
              
      
                const linkArray = link.split("://");
                const starlink = linkArray[1];
                setStarlink(starlink)
      
                window.open('http://'+starlink, '_blank');
            }


      async function category(event, mainfilter, tab) {

        event.preventDefault();

        setMainGender()

        setFeatured(false)

        setTab(tab)


        let value = document.getElementById("default-search").value;
        let genput = document.getElementById("default-search").value;
        //console.log(mainfilter)


        if(value=="male" || value=="Male" || value=="males" || value=="Males" || value=="Men" || value=="men" || value=="Guys" || value=="guys"){
           value='m'
           //setGenderValue('Male')
        }
        else if(value=="female" || value=="Female" || value=="Woman" || value=="woman" || value=="women" || value=="Women" || value=="girl" || value=="Girl" || value=="girls" || value=="Girls") {
          value='f'
          //setGenderValue('Female')
        }
        else if(value=="couple" || value=="Couple") {
          //setGenderValue('c')
          value='c'
          //setGenderValue('Couple')
        }
        else if(value=="trans" || value=="Trans") {
          //setGenderValue('t')
          value='t'
          //setGenderValue('Trans')
        }

        let toddlers = users.filter((users) => users.username.toLowerCase() == value.toLowerCase())

        //console.log(toddlers[0])

        let tagsInput = users.filter((users) => users.tags[0] == value.toLowerCase())
        //console.log(tagsInput, "success")

        
        let gender = users.filter((users) => users.gender == value.toLowerCase())
        //console.log(gender, "success")

        let maingender = users.filter((users) => users.gender == mainfilter)
        //console.log(maingender, "wweew")

        // if(toddlers.length=0 && tagsInput.length==0 && gender.length==0 && maingender.length==0) {
        //   setEmptyFilters(true)
        //   console.log(emptyFilters)
        // }



        // if( (value=='' || value.length==0 || value==null || value==undefined || toddlers==undefined || toddlers==null || toddlers.lenght == 0  && tagsInput==undefined || tagsInput==null || tagsInput.length == 0)) {


        //   window.open(starlink)


        //   setFilter(false)
        //   resetFilters()
        // }

        if(toddlers) {
          //console.log(toddlers)
          setFilter(true)
          setUsernameVal(value)
          setUsernameFilter(true)
          setSearch(toddlers)
        }

        if(maingender) {
          console.log(maingender, "mmada")
          setMainGM(true)
          //resetNotMainGenderFilters()
          setMainGender(Array.from(maingender))
          //setFilter(true)

        }



        // else {
        //   setFilter(false)
        //   setMainGM(false)
        // }

        if(tagsInput) {
           setFilter(true)
           setTagsInput(Array.from(tagsInput))
           setTagsFilter(value)
         }
        //  else {
        //    setFilter(false)
        //    //resetFilters()
        //  }

         if(gender) {
          setGenderFilter(gender)
          setGenderInput(value)
          setGenPut(genput)
          setFilter(true)
        }
        // else {
        //   setFilter(false)
        //   resetNotGenderFilters()
        //   //resetFilters()
        // }

        if(!toddlers && !gender && !tagsInput) {
          setFilter(false)
          resetFilters()
        }

        if(toddlers.length==0 && gender.length==0 && tagsInput.length==0 && mainGender==0 && value.length!=0 && genPut.length!=0 && mainfilter!="") {
          setEmptyFilters(true)
        }
        if(value=="" || tab) {
          setEmptyFilters(false)
        }

        console.log(mainfilter, "mainf")
        if(mainfilter=='c' || mainfilter=='m' || mainfilter=='f' || mainfilter=='featured' || mainfilter=='t'){
          setSearch()
          setGenderFilter()
        }


        if(value.length==0 && mainfilter=='') {
          setFilter(false)
          resetFilters()
          return false;
        }



      }

      function resetFilters() {
        setFilter(false)
        setEmpty(true)
        setUsernameVal()
        setUsernameFilter()
        setSearch()
        setTagsInput()
        setTagsFilter()
        setGenderFilter()
        setGenderInput()
      }

      function resetNotTagFilters() {
        setUsernameFilter()
        setSearch()
        setUsernameVal()
        setGenderFilter()
        setGenderInput()
      }

      function resetNotUsernameFilters() {
        setTagsInput()
        setTagsFilter()
        setGenderFilter()
        setGenderInput()
        setGenderFilter()
        setMainGender()
      }

      function resetNotGenderFilters() {
        setUsernameVal()
        setUsernameFilter()
        setSearch()
        setTagsInput()
        setTagsFilter()
      }

      function resetNotMainGenderFilters() {
        setUsernameVal()
        setUsernameFilter()
        setSearch()
        setTagsInput()
        setTagsFilter()
        setGenderFilter()
        setGenderInput()
      }

     
      

      function hover() {
        document.getElementById('stream').style.color = '#77777';
      }

      // function hovervideo(index) {
      //   document.getElementsByTagName('bonga').src = `${bongaUsers[index].thumbnail_image_big_live}`
      // }
      console.log(emptyFilters)



  return (
      <>
      <Helmet>
      <title>ChatnBang.com — Find OnlyFans — OnlyFans Finder</title>
      <meta name='description' content="ChatNBang, the ultimate dating and creator platform where connections thrive. Unleash your creativity and forge meaningful relationships with like-minded individuals. Join ChatNBang today to explore a new world of authentic connections and artistic expression."></meta>
      </Helmet>

        {/* <div><Drawer className=''/></div> */}
      <div className="flex dark:bg-yttitle/90 min-h-screen h-full w-screen pb-20">

      <div className="flex w-1/4 h-screen fixed">
      <Sidebar/>
      </div>

      <div className="flex flex-col gap-y-5 ml-auto items-start px-5 w-3/4 h-full pt-3 pb-10">

             <div className="flex w-full h-fit mx-auto gap-x-2 mr-auto ml-3 sm:mt-2 md:mt-0 select-none font-semibold">
            
                    <a className={`rounded-[3px] px-2 hover:bg-[#35aee6] ${
                    tab === 0
                    ? 'bg-[#35aee6] text-white dark:bg-[#191D24] dark:text-[#C5CEDD]'
                    : 'bg-[#3BBDF8] text-white dark:bg-[#191D24] dark:text-[#C5CEDD]'
                    }`} onClick={()=>{
                    setFeatured(true)
                    filter=false
                    resetFilters()
                    setTab(0)}}>Featured</a> 
                    <a className={`rounded-[3px] px-2 ${
                    tab === 1
                    ? 'bg-[#35aee6] text-white dark:bg-[#191D24] dark:text-[#C5CEDD]'
                    : 'bg-[#3BBDF8] text-white dark:bg-[#191D24] dark:text-[#C5CEDD]'
                    }`} onClick={(e)=> {category(e, 'f', 1)}}>Women</a> 
                    <a className={`rounded-[3px] px-2 ${
                    tab === 2
                    ? 'bg-[#35aee6] text-white dark:bg-[#191D24] dark:text-[#C5CEDD]'
                    : 'bg-[#3BBDF8] text-white dark:bg-[#191D24] dark:text-[#C5CEDD]'
                    }`} onClick={(e)=> {category(e, 'm', 2)}}>Men</a> 
                    <a className={`rounded-[3px] px-2 ${
                    tab === 3
                    ? 'bg-[#35aee6] text-white dark:bg-[#191D24] dark:text-[#C5CEDD]'
                    : 'bg-[#3BBDF8] text-white dark:bg-[#191D24] dark:text-[#C5CEDD]'
                    }`} onClick={(e)=> {category(e, 'c', 3)}}>Couples</a>
                    <a className={`rounded-[3px] px-2 ${
                    tab === 4
                    ? 'bg-[#35aee6] text-white dark:bg-[#191D24] dark:text-[#C5CEDD]'
                    : 'bg-[#3BBDF8] text-white dark:bg-[#191D24] dark:text-[#C5CEDD]'
                    }`} onClick={(e)=> {category(e, 't', 4)}}>Trans</a>
            </div>

        <form onSubmit={(e)=> {category(e, '')}} className="w-full max-w-sm flex ml-auto justify-center">
            <div className="flex items-center border-b border-nightinfo dark:border-brightpurple py-2">
              <input id="default-search" className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Search" aria-label="Full name"/>
              <button className="flex-shrink-0 bg-nightinfo dark:bg-brightpurple hover:bg-nightinfo/90 dark:hover:bg-brightpurple/90 hover:border-nightinfo border-nightinfo dark:hover:border-brightpurple dark:border-brightpurple hover:border-nightinfo/30 text-sm border-4 text-white py-1 px-2 rounded" type="submit">
                Search
              </button>
            </div>
        </form>


     

       


      <div className="flex w-full h-full">

        {filter==false && (
        
        <>

            {/* <div className="tabs flex w-full mr-auto ml-3 sm:mt-2 md:mt-0">
              
                   
                    <a className="tab tab-lifted tab-active" onClick={filter==false}>Featured</a> 
                
                    <a className="tab tab-lifted dark:bg-[#191D24] dark:text-[#C5CEDD]" onClick={(e)=> {category(e, 'm')}}>Men</a> 
                    <a className="tab tab-lifted dark:bg-[#191D24] dark:text-[#C5CEDD]" onClick={(e)=> {category(e, 'f')}}>Women</a> 
                    <a className="tab tab-lifted dark:bg-[#191D24] dark:text-[#C5CEDD]" onClick={(e)=> {category(e, 'c')}}>Couples</a>
                    <a className="tab tab-lifted dark:bg-[#191D24] dark:text-[#C5CEDD]" onClick={(e)=> {category(e, 't')}}>Trans</a>
            </div> */}

            

            {/* <div className="sm:hidden absolute lg:flex right-0 h-fit w-fit">
            <iframe scrolling="no" frameborder="0" width="160" height="650" src="https://bngdyn.com/promo.php?c=772324&type=dynamic_banner&new_banner=1&db%5Bwidth%5D=160&db%5Bheight%5D=650&db%5Btype%5D=live&db%5Bmodel_zone%5D=free&db%5Bheader%5D=1&db%5Bfooter%5D=footer_text_1&db%5Bmlang%5D=1&db%5Bfullscreen%5D=&db%5Bmname%5D=1&db%5Bmlink%5D=1&db%5Bmstatus%5D=0&db%5Bmsize%5D=auto&db%5Bmpad%5D=12&db%5Bmwidth%5D=60&db%5Bcolor_scheme%5D=light&db%5Bmborder%5D=solid&db%5Bmborder_color%5D=%23999999&db%5Bmborder_over_color%5D=%23a02239&db%5Bmshadow%5D=0&db%5Bmodels_by_geo%5D=0&db%5Bautoupdate%5D=1&db%5Btopmodels%5D=1&db%5Blanding%5D=chat&db%5Blogo_color%5D=default&db%5Blogo_align%5D=left&db%5Bbg_color%5D=%23ffffff&db%5Bfont_family%5D=Arial&db%5Btext_align%5D=center&db%5Btext_color%5D=%23000000&db%5Blink_color%5D=%23a02239&db%5Beffect%5D=auto&db%5Beffect_speed%5D=optimal&db%5Bmode%5D=mode1&db%5Badaptive%5D=0&db%5Bslider%5D=0&db%5Bchat%5D=0"></iframe>
                </div> */}
              <div className="w-full h-full grid lg:grid-cols-6 md:grid-cols-6 sm:grid-cols-1 gap-x-2 gap-y-2 place-items-center mr-auto ml-3 font-bold" onLoad={resetFilters}>
            
              {userListJSON && bongaListJSON && users && starlink && userListJSON.results.map((element, index) => (
          
                <div id="stream" className="token_row lg:px-0 cursor-pointer border rounded-[1px] border-slate-500/50 hover:bg-slate-400/20 dark:hover:bg-slate-400/30" onMouseOver={() => {hover()}} onClick={() => { click(index) }} key={index}>
                  <a id="linkem" target="_blank" className="h-[65%]">
                    <div className="relative">
                      <img className="rounded-[1px]" id="image" alt={`${element.username} live cam`} src={element.image_url}/>
                      {/* <span className="bg-[#3C4451] text-white w-fit h-fit absolute top-[8px] left-[11px] rounded-[2px] border-none outline-transparent"><h2 className="text-[10px] bg-[#3ABEF8] outline-transparent leading-3 px-1">LIVE</h2></span> */}
                      {element.is_hd == true && (
                          <span className="bg-[#3C4451] p-[1px] text-white w-fit h-fit absolute top-[8px] right-[11px] rounded-[2px]"><h2 className="text-[10px] leading-3">HD+</h2></span>
                      )}
                      <span className="absolute bottom-0 w-full bg-tabublack/70 h-[14%] flex">
                        <div className="w-full h-full flex">
                          <h3 className="text-white text-xs flex ml-2 my-auto">{element.username}</h3>
                          <div className="ml-auto mr-2 my-auto flex">
                            <h2 className="text-darkmodewhite text-xs mr-1">{element.num_users}</h2>
                            <span className="flex w-4 h-4 text-white"><EyeIcon/></span>
                          </div>
                        </div>
                      </span>
                    </div>
                  <div className="flex flex-col">
                    <h2 id="tokendesc" className="text-xs w-[90%] max-h-[43px] text-yttitle dark:text-ytnighttitle/90 mx-auto mt-[8px] overflow-hidden text-ellipsis">{element.room_subject}</h2>
                        <div className="contentgrid grid grid-cols-3">
                            <div className="titlepfp">
                              <div class="flex flex-col w-[90%] mx-auto self-center ml-5 mt-[2px]"> 
                                <div id="userdiv" class="flex">
                                  {/* <p id="username" class="flex text-yttitle dark:text-ytnightuser capitalize text-[12px] leading-[20px] font-[400]">{element.username}
                                  {element.num_followers>=300000 && (
                                    <span class=" text-black dark:text-ytnightuser flex ml-2"><div id="verified" class="w-[10px] h-[10px] self-center"></div></span>
                                  )}
                                  {element.num_followers<300000 && (
                                      <span class=" text-black dark:text-ytnightuser flex ml-2"><div class="w-[10px] h-[10px] self-center"></div></span>
                                  )}
                                  </p> */}
    
                                </div>
    
                             
    
                              </div>
                              
                            </div>
    
                      
                        </div>
                        {/* <div class="w-full h-full flex ml-5">
                                  <p class="flex place-self-center text-[12px] leading-[20px] font-[500] text-ytuser dark:text-ytnightuser text-left">{element.num_users} <span className="ml-1">viewers</span> <span class="px-1 text-gray-600/60 font-[300] text-[4px] mt-[1px]">●</span> {Math.round(element.seconds_online/60)} <span className="ml-1 flex">minutes live</span> </p>
                        </div> */}
                    </div>
                      </a>
                    
                </div>

                
              ))}

              

   
           
              {bongaUsers && bongaListJSON && userListJSON && bongaUsers.map((element, index) => (
                <div id="stream" className="token_row lg:px-0 cursor-pointer hover:bg-slate-400/20 dark:hover:bg-slate-400/30" onMouseOver={() => {hover()}} onClick={() => { click(index) }} key={index}>
                
                  <a id="linkem" name="bonga" target="_blank" className="h-[65%]">
                    <div className="relative">
                      <img className="rounded-[2px]" id="image" alt={`${element.username} live cam`} src={element.profile_images.thumbnail_image_big} />
                      {/* <span className="bg-[#3C4451] text-white w-fit h-fit absolute top-[8px] left-[11px] rounded-[2px] border-none outline-transparent">
                        <h2 className="text-[10px] bg-[#00D616] outline-transparent leading-3 px-1">LIVE</h2></span> */}
                      {element.hd_cam && element.members_count>200 == true && (
                        <span className="bg-[#3C4451] p-[1px] text-white w-fit h-fit absolute top-[8px] right-[11px] rounded-[2px]"><h2 className="text-[10px] leading-3">HD+</h2></span>
                      )}
                      <span className="absolute bottom-0 w-full bg-tabublack/70 h-[14%] flex">
                        <div className="w-full h-full flex">
                          <h3 className="text-white text-xs flex ml-2 my-auto">{element.username}</h3>
                          <div className="ml-auto mr-2 my-auto flex">
                            <h2 className="text-darkmodewhite text-xs mr-1">{element.members_count}</h2>
                            <span className="flex w-4 h-4 text-white"><EyeIcon/></span>
                          </div>
                        </div>
                      </span>
                    </div>
                <div className="flex flex-col">
                  <p id="tokendesc" className="text-xs w-[90%] max-h-[43px] text-yttitle dark:text-ytnighttitle/90 ml-auto mr-[10px] mt-[8px] overflow-hidden text-ellipsis">#{element.tags[0]} #{element.tags[1]} #{element.tags[2]}</p>
                      {/* <div className="contentgrid grid grid-cols-3">

                          <div className="titlepfp">
                            <div class="flex flex-col w-[90%] mx-auto self-center ml-5 mt-[6px]"> 
                              <div id="userdiv" class="flex">
                                <p id="username" class="flex text-yttitle dark:text-ytnightuser capitalize text-[12px] leading-[20px] font-[400]">{element.display_name}
                                {element.members_count>=1000 && (
                                  <span class=" text-black dark:text-ytnightuser flex ml-2"><div id="verified" class="w-[10px] h-[10px] self-center"></div></span>
                                )}
                                {element.members_count<1000 && (
                                    <span class=" text-black dark:text-ytnightuser flex ml-2"><div class="w-[10px] h-[10px] self-center"></div></span>
                                )}
                                </p>
  
                              </div>
  
                           
  
                            </div>
                            
                          </div>
  
                    
                      </div>
                      <div class="w-full h-full flex ml-5">
                                <p class="flex place-self-center text-[12px] leading-[20px] font-[500] text-ytuser dark:text-ytnightuser text-left">{element.members_count} <span className="ml-1">viewers</span> <span class="px-1 text-gray-600/60 font-[300] text-[4px] mt-[1px]">●</span> {Math.round(element.online_time/60)} <span className="ml-1 flex">minutes live</span> </p>
                      </div> */}
                  </div>
                    </a>
                    
              </div>
            
              ))}
            </div>
            
          </>
          
        )}

        {mainGender!='' && mainGender!=undefined && mainGender[0].gender=='m' && (
        
        <>

              <div className="w-full h-full grid lg:grid-cols-4 md:grid-cols-6 sm:grid-cols-1 gap-5 place-items-center mr-auto ml-3 font-bold">
              
              {userListJSON && users && starlink && mainGender.map((element, index) => (
                <div className="token_row cursor-pointer hover:bg-slate-400/20 dark:hover:bg-slate-400/30" onClick={() => { clickmaingender(index) }} key={index}>
                  <a id="linkem" target="_blank" className="h-[65%]"><img className="rounded-[5px]" id="image" alt={`${element.username} live cam`} src={element.image_url}/>
                  <div className="flex flex-col">
                    <h2 id="tokendesc" className="text-sm w-[90%] max-h-[43px] text-yttitle dark:text-ytnighttitle/90 ml-auto mr-[10px] mt-[8px] overflow-hidden text-ellipsis">{element.room_subject}</h2>
                        <div className="contentgrid grid grid-cols-3">
                            <div className="titlepfp">
                              <div class="flex flex-col w-[90%] mx-auto self-center ml-5 mt-[6px]"> 
                                <div id="userdiv" class="flex">
                                  <p id="username" class="flex text-yttitle dark:text-ytnightuser capitalize text-[12px] leading-[20px] font-[400]">{element.username}
                                  {element.num_followers>=300000 && (
                                    <span class=" text-black dark:text-ytnightuser flex ml-2"><div id="verified" class="w-[10px] h-[10px] self-center"></div></span>
                                  )}
                                  {element.num_followers<300000 && (
                                      <span class=" text-black dark:text-ytnightuser flex ml-2"><div class="w-[10px] h-[10px] self-center"></div></span>
                                  )}
                                  </p>
    
                                </div>
    
                             
    
                              </div>
                              
                            </div>
    
                      
                        </div>
                        <div class="w-full h-full flex ml-5">
                                  <p class="flex place-self-center text-[12px] leading-[20px] font-[500] text-ytuser dark:text-ytnightuser text-left">{element.num_users} <span className="ml-1">viewers</span> <span class="px-1 text-gray-600/60 font-[300] text-[4px] mt-[1px]">●</span> {Math.round(element.seconds_online/60)} <span className="ml-1 flex">minutes live</span> </p>
                        </div>
                    </div>
                      </a>
                      
                </div>
    
                
              ))}
            </div>
          </>
        )}

        {mainGender!='' && mainGender!=undefined && mainGender[0].gender=='f' && (
        
        <>

              <div className="w-full h-full grid lg:grid-cols-4 md:grid-cols-6 sm:grid-cols-1 gap-5 place-items-center mr-auto ml-3 font-bold">
              
              {userListJSON && users && starlink && mainGender.map((element, index) => (
                <div className="token_row hover:bg-slate-400/20 dark:hover:bg-slate-400/30" onClick={() => { clickmaingender(index) }} key={index}>
                  <a id="linkem" target="_blank" className="h-[65%]"><img className="rounded-[5px]" id="image" alt={`${element.username} live cam`} src={element.image_url}/>
                  <div className="flex flex-col">
                    <h2 id="tokendesc" className="text-sm w-[90%] max-h-[43px] text-yttitle dark:text-ytnighttitle/90 ml-auto mr-[10px] mt-[8px] overflow-hidden text-ellipsis">{element.room_subject}</h2>
                        <div className="contentgrid grid grid-cols-3">
                            <div className="titlepfp">
                              <div class="flex flex-col w-[90%] mx-auto self-center ml-5 mt-[6px]"> 
                                <div id="userdiv" class="flex">
                                  <p id="username" class="flex text-yttitle dark:text-ytnightuser capitalize text-[12px] leading-[20px] font-[400]">{element.username}
                                  {element.num_followers>=300000 && (
                                    <span class=" text-black dark:text-ytnightuser flex ml-2"><div id="verified" class="w-[10px] h-[10px] self-center"></div></span>
                                  )}
                                  {element.num_followers<300000 && (
                                      <span class=" text-black dark:text-ytnightuser flex ml-2"><div class="w-[10px] h-[10px] self-center"></div></span>
                                  )}
                                  </p>
    
                                </div>
    
                             
    
                              </div>
                              
                            </div>
    
                      
                        </div>
                        <div class="w-full h-full flex ml-5">
                                  <p class="flex place-self-center text-[12px] leading-[20px] font-[500] text-ytuser dark:text-ytnightuser text-left">{element.num_users} <span className="ml-1">viewers</span> <span class="px-1 text-gray-600/60 font-[300] text-[4px] mt-[1px]">●</span> {Math.round(element.seconds_online/60)} <span className="ml-1 flex">minutes live</span> </p>
                        </div>
                    </div>
                      </a>
                      
                </div>
    
                
              ))}
            </div>
          </>
        )}

        {mainGender!='' && mainGender!=undefined && mainGender[0].gender=='c' && (
        
        <>

              <div className="w-full h-full grid lg:grid-cols-4 md:grid-cols-6 sm:grid-cols-1 gap-5 place-items-center mr-auto ml-3 font-bold">
              
              {userListJSON && users && starlink && mainGender.map((element, index) => (
                <div className="token_row hover:bg-slate-400/20 dark:hover:bg-slate-400/30" onClick={() => { clickmaingender(index) }} key={index}>
                  <a id="linkem" target="_blank" className="h-[65%]"><img className="rounded-[5px]" id="image" alt={`${element.username} live cam`} src={element.image_url}/>
                  <div className="flex flex-col">
                    <h2 id="tokendesc" className="text-sm w-[90%] max-h-[43px] text-yttitle dark:text-ytnighttitle/90 ml-auto mr-[10px] mt-[8px] overflow-hidden text-ellipsis">{element.room_subject}</h2>
                        <div className="contentgrid grid grid-cols-3">
                            <div className="titlepfp">
                              <div class="flex flex-col w-[90%] mx-auto self-center ml-5 mt-[6px]"> 
                                <div id="userdiv" class="flex">
                                  <p id="username" class="flex text-yttitle dark:text-ytnightuser capitalize text-[12px] leading-[20px] font-[400]">{element.username}
                                  {element.num_followers>=300000 && (
                                    <span class=" text-black dark:text-ytnightuser flex ml-2"><div id="verified" class="w-[10px] h-[10px] self-center"></div></span>
                                  )}
                                  {element.num_followers<300000 && (
                                      <span class=" text-black dark:text-ytnightuser flex ml-2"><div class="w-[10px] h-[10px] self-center"></div></span>
                                  )}
                                  </p>
    
                                </div>
    
                             
    
                              </div>
                              
                            </div>
    
                      
                        </div>
                        <div class="w-full h-full flex ml-5">
                                  <p class="flex place-self-center text-[12px] leading-[20px] font-[500] text-ytuser dark:text-ytnightuser text-left">{element.num_users} <span className="ml-1">viewers</span> <span class="px-1 text-gray-600/60 font-[300] text-[4px] mt-[1px]">●</span> {Math.round(element.seconds_online/60)} <span className="ml-1 flex">minutes live</span> </p>
                        </div>
                    </div>
                      </a>
                      
                </div>
    
                
              ))}
            </div>
          </>
        )}

        {mainGender!='' && mainGender!=undefined && mainGender[0].gender=='t' && (
        
        <>

              <div className="w-full h-full grid lg:grid-cols-4 md:grid-cols-6 sm:grid-cols-1 gap-5 place-items-center mr-auto ml-3 font-bold">
              
              {userListJSON && users && starlink && mainGender.map((element, index) => (
                <div className="token_row hover:bg-slate-400/20 dark:hover:bg-slate-400/30" onClick={() => { clickmaingender(index) }} key={index}>
                  <a id="linkem" target="_blank" className="h-[65%]"><img className="rounded-[5px]" id="image" alt={`${element.username} live cam`} src={element.image_url}/>
                  <div className="flex flex-col">
                    <h2 id="tokendesc" className="text-sm w-[90%] max-h-[43px] text-yttitle dark:text-ytnighttitle/90 ml-auto mr-[10px] mt-[8px] overflow-hidden text-ellipsis">{element.room_subject}</h2>
                        <div className="contentgrid grid grid-cols-3">
                            <div className="titlepfp">
                              <div class="flex flex-col w-[90%] mx-auto self-center ml-5 mt-[6px]"> 
                                <div id="userdiv" class="flex">
                                  <p id="username" class="flex text-yttitle dark:text-ytnightuser capitalize text-[12px] leading-[20px] font-[400]">{element.username}
                                  {element.num_followers>=300000 && (
                                    <span class=" text-black dark:text-ytnightuser flex ml-2"><div id="verified" class="w-[10px] h-[10px] self-center"></div></span>
                                  )}
                                  {element.num_followers<300000 && (
                                      <span class=" text-black dark:text-ytnightuser flex ml-2"><div class="w-[10px] h-[10px] self-center"></div></span>
                                  )}
                                  </p>
    
                                </div>
    
                             
    
                              </div>
                              
                            </div>
    
                      
                        </div>
                        <div class="w-full h-full flex ml-5">
                                  <p class="flex place-self-center text-[12px] leading-[20px] font-[500] text-ytuser dark:text-ytnightuser text-left">{element.num_users} <span className="ml-1">viewers</span> <span class="px-1 text-gray-600/60 font-[300] text-[4px] mt-[1px]">●</span> {Math.round(element.seconds_online/60)} <span className="ml-1 flex">minutes live</span> </p>
                        </div>
                    </div>
                      </a>
                      
                </div>
    
                
              ))}
            </div>
          </>
        )}


        {filter===true && search!=null && search!=undefined && search.length!=0 && (

          <>

          <div className="flex mb-auto w-[90%] mx-auto">
            <h3 className="text-2xl font-bold flex mb-auto">Videos with {usernameVal}:</h3>
          </div>
        
          <div id="off" className="w-full h-full min-h-[68vh] grid lg:grid-cols-4 md:grid-cols-6 sm:grid-cols-1 gap-5 mx-auto font-bold mt-[5vh]" onLoad={resetNotUsernameFilters}>

              {userListJSON && users && starlink && Array.from(search).map((element, index) => (
                <div className="token_row hover:bg-slate-400/20 h-[60%] dark:hover:bg-slate-400/30" onClick={() => { clickusername(index) }} key={index}>
                  <a id="linkem" target="_blank" className="h-[65%]"><img className="rounded-[5px]" id="image" alt={`${element.username} live cam`} src={element.image_url}/>
                  <div className="flex flex-col">
                    <h2 id="tokendesc" className="text-sm w-[90%] max-h-[43px] text-yttitle ml-auto mr-[10px] mt-[8px] overflow-hidden text-ellipsis">{element.room_subject}</h2>
                        <div className="contentgrid grid grid-cols-3">
                            <div className="titlepfp">
                              <div class="flex flex-col w-[90%] mx-auto self-center ml-5 mt-[6px]"> 
                                <div id="userdiv" class="flex">
                                  <p id="username" class="flex text-yttitle capitalize text-[12px] leading-[20px] font-[400]">{element.username}
                                  {element.num_followers>=300000 && (
                                    <span class=" text-black flex ml-2"><div id="verified" class="w-[10px] h-[10px] self-center"></div></span>
                                  )}
                                  {element.num_followers<300000 && (
                                      <span class=" text-black flex ml-2"><div class="w-[10px] h-[10px] self-center"></div></span>
                                  )}
                                  </p>

                                </div>

                            

                              </div>
                              
                            </div>

                      
                        </div>
                        <div class="w-full h-full flex ml-5">
                                  <p class="flex place-self-center text-[12px] leading-[20px] font-[500] text-ytuser text-left">{element.num_users} <span className="ml-1">viewers</span> <span class="px-1 text-gray-600/60 font-[300] text-[4px] mt-[1px]">●</span> {Math.round(element.seconds_online/60)} <span className="ml-1 flex">minutes live</span> </p>
                        </div>
                    </div>
                      </a>
                      
                </div>
            

            
          ))}
            </div>




          

          </>
          
        )}

{tagsInput!=null && tagsInput!=undefined && tagsInput.length!=0 && (


<>
  <div className="flex mb-auto w-[90%] mx-auto">
    <p className="text-2xl font-bold flex mb-auto mt-1">Videos with #{tagsFilter}:</p>
  </div>
  <div id="off" className="w-full h-full grid lg:grid-cols-4 md:grid-cols-6 sm:grid-cols-1 gap-5 place-items-center mx-auto font-bold mt-[5vh]" onLoad={resetNotTagFilters}>

{userListJSON && users && starlink && Array.from(tagsInput).map((element, index) => (
  <div className="token_row hover:bg-slate-400/20 dark:hover:bg-slate-400/30" onClick={() => { clicktag(index) }} key={index}>
    <a id="linkem" target="_blank" className="h-[65%]"><img alt={`${element.username} live cam`} className="rounded-[5px]" id="image" src={element.image_url}/>
    <div className="flex flex-col">
      <h2 id="tokendesc" className="text-sm w-[90%] max-h-[43px] text-yttitle ml-auto mr-[10px] mt-[8px] overflow-hidden text-ellipsis">{element.room_subject}</h2>
          <div className="contentgrid grid grid-cols-3">
              <div className="titlepfp">
                <div class="flex flex-col w-[90%] mx-auto self-center ml-5 mt-[6px]"> 
                  <div id="userdiv" class="flex">
                    <p id="username" class="flex text-yttitle capitalize text-[12px] leading-[20px] font-[400]">{element.username}
                    {element.num_followers>=300000 && (
                      <span class=" text-black flex ml-2"><div id="verified" class="w-[10px] h-[10px] self-center"></div></span>
                    )}
                    {element.num_followers<300000 && (
                        <span class=" text-black flex ml-2"><div class="w-[10px] h-[10px] self-center"></div></span>
                    )}
                    </p>

                  </div>

              

                </div>
                
              </div>

        
          </div>
          <div class="w-full h-full flex ml-5">
                    <p class="flex place-self-center text-[12px] leading-[20px] font-[500] text-ytuser text-left">{element.num_users} <span className="ml-1">viewers</span> <span class="px-1 text-gray-600/60 font-[300] text-[4px] mt-[1px]">●</span> {Math.round(element.seconds_online/60)} <span className="ml-1 flex">minutes live</span> </p>
          </div>
      </div>
        </a>
        
  </div>

  
))}
</div>





</>

)}

{emptyFilters=true || !emptyFilters && (


<>
  <div className="flex mb-auto w-[90%] mx-auto mt-10">
    <h3 className="text-2xl font-bold flex mb-auto mt-1 mx-auto">Search not found</h3>
  </div>
  <div id="off" className="w-full h-full grid lg:grid-cols-4 md:grid-cols-6 sm:grid-cols-1 gap-5 place-items-center mx-auto font-bold mt-[5vh]" onLoad={resetNotTagFilters}>

{userListJSON && users && starlink && Array.from(tagsInput).map((element, index) => (
  <div className="token_row hover:bg-slate-400/20 dark:hover:bg-slate-400/30" onClick={() => { clicktag(index) }} key={index}>
    <a id="linkem" target="_blank" className="h-[65%]"><img alt={`${element.username} live cam`} className="rounded-[5px]" id="image" src={element.image_url}/>
    <div className="flex flex-col">
      <h2 id="tokendesc" className="text-sm w-[90%] max-h-[43px] text-yttitle ml-auto mr-[10px] mt-[8px] overflow-hidden text-ellipsis">{element.room_subject}</h2>
          <div className="contentgrid grid grid-cols-3">
              <div className="titlepfp">
                <div class="flex flex-col w-[90%] mx-auto self-center ml-5 mt-[6px]"> 
                  <div id="userdiv" class="flex">
                    <p id="username" class="flex text-yttitle capitalize text-[12px] leading-[20px] font-[400]">{element.username}
                    {element.num_followers>=300000 && (
                      <span class=" text-black flex ml-2"><div id="verified" class="w-[10px] h-[10px] self-center"></div></span>
                    )}
                    {element.num_followers<300000 && (
                        <span class=" text-black flex ml-2"><div class="w-[10px] h-[10px] self-center"></div></span>
                    )}
                    </p>

                  </div>

              

                </div>
                
              </div>

        
          </div>
          <div class="w-full h-full flex ml-5">
                    <p class="flex place-self-center text-[12px] leading-[20px] font-[500] text-ytuser text-left">{element.num_users} <span className="ml-1">viewers</span> <span class="px-1 text-gray-600/60 font-[300] text-[4px] mt-[1px]">●</span> {Math.round(element.seconds_online/60)} <span className="ml-1 flex">minutes live</span> </p>
          </div>
      </div>
        </a>
        
  </div>

  
))}
</div>





</>

)}

{genderFilter!=null && genderFilter!=undefined && genderFilter.length!=0 && (


<>
  <div className="flex mb-auto w-[90%] mx-auto">
    <h3 className="text-2xl font-bold flex mb-auto">{genPut} videos:</h3>
  </div>
  <div id="off" className="w-full h-full grid lg:grid-cols-4 md:grid-cols-6 sm:grid-cols-1 gap-5 place-items-center mx-auto font-bold mt-[5vh]" onLoad={resetNotGenderFilters}>

{userListJSON && users && starlink && Array.from(genderFilter).map((element, index) => (
  <div className="token_row hover:bg-slate-400/20 dark:hover:bg-slate-400/30" onClick={() => { clickgender(index) }} key={index}>
    <a id="linkem" target="_blank" className="h-[65%]"><img alt={`${element.username} live cam`} className="rounded-[5px]" id="image" src={element.image_url}/>
    <div className="flex flex-col">
      <h2 id="tokendesc" className="text-sm w-[90%] max-h-[43px] text-yttitle ml-auto mr-[10px] mt-[8px] overflow-hidden text-ellipsis">{element.room_subject}</h2>
          <div className="contentgrid grid grid-cols-3">
              <div className="titlepfp">
                <div class="flex flex-col w-[90%] mx-auto self-center ml-5 mt-[6px]"> 
                  <div id="userdiv" class="flex">
                    <p id="username" class="flex text-yttitle capitalize text-[12px] leading-[20px] font-[400]">{element.username}
                    {element.num_followers>=300000 && (
                      <span class=" text-black flex ml-2"><div id="verified" class="w-[10px] h-[10px] self-center"></div></span>
                    )}
                    {element.num_followers<300000 && (
                        <span class=" text-black flex ml-2"><div class="w-[10px] h-[10px] self-center"></div></span>
                    )}
                    </p>

                  </div>

              

                </div>
                
              </div>

        
          </div>
          <div class="w-full h-full flex ml-5">
                    <p class="flex place-self-center text-[12px] leading-[20px] font-[500] text-ytuser text-left">{element.num_users} <span className="ml-1">viewers</span> <span class="px-1 text-gray-600/60 font-[300] text-[4px] mt-[1px]">●</span> {Math.round(element.seconds_online/60)} <span className="ml-1 flex">minutes live</span> </p>
          </div>
      </div>
        </a>
        
  </div>

  
))}
</div>





</>

)}

  </div>

  </div>
</div>




        
      </>

      

      


  )
}



export default ParseXml
