import React from 'react'

const AgeVerification = () => {
  return (
    <div className="fixed justify-center md:hidden bg-black/90 w-screen h-screen items-center p-10 rounded-md mx-auto flex flex-col select-none z-[200]">
    <h2 className="font-semibold text-2xl text-center text-white">Age Verification</h2>
    <h3 className="font-normal text-md text-center text-white">Are you 18 or older?</h3>
    <div className="w-full flex gap-x-3 justify-center items-center mt-4">
        <a href="https://onlyfans.com/your.dandelion/c23" className="shadow-lg w-[200px] h-[35px] md-h-fit md-w-fit pt-1 text-center shadow-red-600/60 rounded-full md:px-7 md:py-3 items-center justify-center content-center border-2 text-white bg-red-600 border-red-600 hover:bg-black hover:text-[#01AEF0] hover:cursor-pointer"><span className="flex md:mb-0 text-center justify-center content-center">YES</span></a>
        <a className="rounded-full w-[200px] h-[35px] md-h-fit md-w-fit md:px-7 md:py-3 pt-1 items-center justify-center content-center border-2 opacity-[50%] border-red-600 hover:text-red-500"><span className="flex md:mb-0 text-center justify-center content-center">NO</span></a>
    </div>
</div>
  )
}

export default AgeVerification
